import React from "react";
import { motion } from "framer-motion";

const CompanyDomain = ({ companyDomain, setCompanyDomain, errors, handleNext }) => {
  const validateDomain = (domain) => {
    const domainPattern = /^(?:[a-zA-Z0-9-]+\.)+(com|org|net|edu|gov|co|us|io|biz|ai)$/;
    return domainPattern.test(domain.trim()); 
  };

  const handleDomainChange = (e) => {
    const domain = e.target.value;
    setCompanyDomain(domain);
  };

  return (
    <>
      <motion.h2 className="text-2xl font-semibold mb-8 text-center text-blue-800">
        Provide Your Company Domain
      </motion.h2>
      <motion.div className="mt-8">
        <label className="block mb-2 font-medium text-gray-800 text-center">
          This will help us tailor your experience and ensure we have accurate information for your account setup.
        </label>
        <input
  type="text"
  className={`w-full px-4 py-3 border rounded-lg focus:outline-none focus:ring-.5 focus:ring-blue-500 text-lg ${
    errors.companyDomain ? "border-red-500 border-2" : "border-gray-300 border-2"
  }`}
  value={companyDomain}
  onChange={handleDomainChange}
  placeholder="example.com"
/>

        {errors.companyDomain && (
          <p className="text-red-500 text-sm mt-2">{errors.companyDomain}</p>
        )}
        {!errors.companyDomain && (
          <p className="text-gray-500 text-sm mt-2">
            Enter your domain without spaces and ensure it ends with a valid domain.
          </p>
        )}
      </motion.div>
    </>
  );
};

export default CompanyDomain;
