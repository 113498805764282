import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import {
  ChevronDownIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import { FaSearch } from "react-icons/fa";
import Layout from "../containers/Layout";
import heroImage from "../images/hero.webp";
import teamsStep2 from "../images/teamsDocumentation/teamsStep2.png";
import teamsStep4 from "../images/teamsDocumentation/teamsStep4.png";
import teamsStep5 from "../images/teamsDocumentation/teamsStep5.png";
import teamsStep6 from "../images/teamsDocumentation/teamsStep6.png";
import teamsStep7 from "../images/teamsDocumentation/teamsStep7.png";
import teamsStep8 from "../images/teamsDocumentation/teamsStep8.png";
import slackStep1 from "../images/slackDocumentation/slackStep1.png";
import slackStep2 from "../images/slackDocumentation/slackStep2.png";
import slackStep3 from "../images/slackDocumentation/slackStep3.png";
import bamboohrStep3 from "../images/bamboohrDocumentation/bamboohrStep3.png";
import mattermostStep1 from "../images/mattermostDocumentation/mattermostStep1.png";
import hibobStep1 from "../images/hibobDocumentation/hibobStep1.png"
import hibobStep2 from "../images/hibobDocumentation/hibobStep2.png"
import hibobStep3 from "../images/hibobDocumentation/hibobStep3.png"
import { checkAuth } from "../utils/AuthCheck";
import validatePayment from "../utils/ValidatePayment";

const Documentation = () => {
  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isDemoUser, setIsDemoUser] = useState(true);
  const [authComplete, setAuthComplete] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    async function authenticateUser() {
      try {
        await checkAuth(setUser, setUserToken, setIsDemoUser);
        setAuthComplete(true);
      } catch (error) {
        console.error("Error during authentication:", error);
      }
    }

    authenticateUser();
  }, [user]);

  useEffect(() => {
    async function checkPaymentStatus() {
      if (user) {
        const hasPaid = await validatePayment(user.uid); // Assuming validatePayment returns a boolean indicating payment status
        if (!hasPaid) {
          window.location.href = "/onboarding"; 
        }
      }
    }
    checkPaymentStatus();
  }, [user]);


  const integrations = [
    {
      title: "Slack",
      description:
        "Integrate Worksense with your Slack workspace to receive notifications and updates directly in Slack.",
      note: "Note: Slack integration currently only works for public channels. You need to have permissions to add an application to Slack.",
      steps: [
        {
          text: "Go to the Integrations page and click on 'Integrate With Slack'.",
          image: slackStep1,
        },
        {
          text: "Make sure to select the correct Slack Workspace you would like to add Worksense to. Then click 'Allow'.",
          image: slackStep2,
        },
        {
          text: "You'll be redirected back to Worksense. Congratulations, you've integrated Slack! You can now check it in the /integrations tab.",
          image: slackStep3,
        },
      ],
    },
    {
      title: "Microsoft Teams",
      description:
        "Integrate Worksense with Microsoft Teams to get realtime data on your team.",
      note: "Note: You must be a Microsoft Teams system admin with the appropriate permissions to complete the integration.",
      steps: [
        {
          text: "Log in to your Microsoft Teams account.",
        },
        {
          text: (
            <>
              Click on the <EllipsisHorizontalIcon className="inline h-5 w-5" />{" "}
              next to the team/teams you want to integrate and select 'Get link
              to channel'.
            </>
          ),
          image: teamsStep2,
        },
        {
          text: "Copy the team ID from the link after 'groupId=' and up to the '&' symbol.",
        },
        {
          text: (
            <>
              Go to the Worksense Integrations page at{" "}
              <a
                href="https://worksenseai.com/integrations"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-600 underline"
              >
                https://worksenseai.com/integrations
              </a>{" "}
              and paste the team ID into the text box.
            </>
          ),
        },
        {
          text: "If you have multiple team IDs, separate them with commas (no spaces) in the text box, like '123abc,345def'.",
        },
        {
          text: "Click on the 'Add Application to Teams Space' button.",
          image: teamsStep4,
        },
        {
          text: "Consent to add the Worksense app to your Microsoft Teams and you will be redirected to the Worksense Integrations page.",
          image: teamsStep5,
        },
        {
          text: "Click on the 'Grant Admin Consent' button and authorize the app to have the necessary permissions.",
          image: teamsStep6,
        },
        {
          text: "Authorize the application with the necessary permissions. Once authorized, you will be redirected back to Worksense.",
          image: teamsStep7,
        },
        {
          text: "Congratulations, you've integrated Teams!",
          image: teamsStep8,
        },
      ],
    },
    {
      title: "BambooHR",
      description:
        "Integrate Worksense with BambooHR to sync employee data and streamline HR processes.",
      note: "Note: When creating a BambooHR API key, use a service account and not an administrator account.",
      steps: [
        {
          text: "Generating a BambooHR API key:",
          substeps: [
            {
              text: "Sign in to your BambooHR instance with administrator permissions.",
            },
            {
              text: "On the Home page, click Account and select API Keys.",
            },
            {
              text: "Click Add New Key.",
            },
            {
              text: "Enter a name for the API key in the API Key Name field and click Generate Key.",
            },
            {
              text: "Click COPY KEY to copy the key to your clipboard.",
            },
            {
              text: "Click Done.",
            },
          ],
        },
        {
          text: (
            <>
              Go to the Worksense Integrations page at{" "}
              <a
                href="https://worksenseai.com/integrations"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-600 underline"
              >
                https://worksenseai.com/integrations
              </a>{" "}
              and paste the API key and BambooHR URL into the corresponding
              fields.
            </>
          ),
          image: bamboohrStep3,
        },
        {
          text: "Click Save to complete the integration.",
        },
      ],
    },
    {
      title: "Mattermost",
      description:
        "Integrate Worksense with Mattermost to get realtime data on your team.",
      note: "Note: You must be a Mattermost system administrator to complete the integration.",
      steps: [
        {
          text: (
            <>
              Go to the Worksense Integrations page at{" "}
              <a
                href="https://worksenseai.com/integrations"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-600 underline"
              >
                https://worksenseai.com/integrations
              </a>{" "}
              and enter the following information:
            </>
          ),
          substeps: [
            {
              text: "The base URL of your Mattermost workspace. Do not include trailing slashes",
            },
            {
              text: "The username of the system admin account",
            },
            {
              text: "The password of the system admin account",
            },
          ],
        },
        {
          text: "Click Save to complete the integration.",
          image: mattermostStep1,
        },
      ],
    },
    {
      title: "HiBob",
      description: "Integrate Worksense with HiBob to sync employee data and streamline HR processes.",
      note: "Note: You must have administrator access to your HiBob account to complete this integration.",
      steps: [
        {
          text: (
            <>
              Before starting, please refer to the{" "}
              <a
                href="https://apidocs.hibob.com/docs/api-service-users"
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-600 underline"
              >
                HiBob API documentation
              </a>
              {" "}for detailed instructions on the following steps:
            </>
          ),
          substeps: [
            { text: "Create a new API service user" },
            { text: "Create a permission group and add the API service user to it" },
            { text: "Set appropriate permissions for the group" }
          ]
        },
        {
          text: "Create a New API Service User:",
          substeps: [
            { text: "In HiBob, navigate to System Settings > Integrations > Automation > Service users." },
            { text: "Create a new service user named 'Worksense'." },
            { text: "Save the provided Service ID and Token securely - you'll need these later." }
          ],
          image: hibobStep1
        },
        {
          text: "Set Up Permissions:",
          substeps: [
            { text: "Create a new permission group or use an existing one for API access." },
            { text: "Add the 'Worksense' service user to this group." },
            { text: "Configure the group's permissions to allow read access to People data." },
            { text: "Ensure financial data access is excluded unless necessary. If it is included all other admins will be alerted" }
          ],
          image: hibobStep2
        },
        {
          text: "Add Integration to Worksense:",
          substeps: [
            { text: "Log in to your Worksense account and navigate to the Integrations page." },
            { text: "Select HiBob from the list of available integrations." },
            { text: "Enter the Service ID and Token you saved earlier." },
            { text: "Click 'Save' to complete the integration." }
          ],
          image: hibobStep3
        }
      ]
    }
  ];

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter integrations based on the search query
  const filteredIntegrations = integrations.filter((integration) =>
    integration.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
      <div className="bg-gray-100">
        <section
          className="hero bg-cover bg-center py-20"
          style={{ backgroundImage: `url(${heroImage})` }}
        >
          <div className="px-4">
            <h1 className="text-5xl font-bold text-white mb-4">
              Worksense Documentation
            </h1>
            <p className="text-xl text-white mb-8">
              Explore our guides and resources to get started and make the most
              out of Worksense.
            </p>
            <div className="flex flex-wrap gap-4">
              <a
                href="/definition-glossary"
                className="bg-white text-purple-600 px-6 py-3 rounded-lg font-semibold hover:bg-purple-100"
              >
                View Definition Glossary
              </a>
              {/* <button className="bg-purple-600 text-white px-6 py-3 rounded-lg font-semibold hover:bg-purple-700">
                Get Started
              </button> */}
            </div>
          </div>
        </section>

        <div className="px-4 py-8">
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <FaSearch className="h-5 w-5 text-gray-400" />
            </div>
            <input
              type="text"
              placeholder="Search documentation..."
              value={searchQuery}
              onChange={handleSearch}
              className="w-full pl-10 pr-4 py-2 rounded-lg shadow-md focus:outline-none focus:ring-2 focus:ring-purple-600"
            />
          </div>
        </div>

        <section className="px-4 py-8">
          <h2 className="text-3xl font-semibold mb-4">Integrations</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {filteredIntegrations.map((integration, index) => (
              <div key={index} className="bg-white rounded-lg shadow-lg p-6">
                {integration.note && (
                  <p className="text-sm text-gray-500 italic mb-4">
                    {integration.note}
                  </p>
                )}
                <div className="flex items-center mb-4">
                  <div className="bg-purple-500 rounded-full p-3 mr-4">
                    {/* Add an icon or illustration */}
                  </div>
                  <h3 className="text-2xl font-semibold">
                    {integration.title}
                  </h3>
                </div>
                <p className="text-gray-600 mb-4">{integration.description}</p>
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-purple-900 bg-purple-100 rounded-lg hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                        <span>Integration Steps</span>
                        <ChevronDownIcon
                          className={`${
                            open ? "transform rotate-180" : ""
                          } w-5 h-5 text-purple-500`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                        <ol className="list-decimal list-inside mb-4">
                          {integration.steps.map((step, index) => (
                            <li key={index} className="mb-4">
                              <p className="font-semibold inline">
                                {step.text}
                              </p>
                              {step.substeps && (
                                <ol className="list-decimal list-inside ml-4 mt-2">
                                  {step.substeps.map((substep, subindex) => (
                                    <li key={subindex} className="mb-2">
                                      {substep.text}
                                    </li>
                                  ))}
                                </ol>
                              )}
                              {step.image && (
                                <img
                                  src={step.image}
                                  alt={`Step ${index + 1}`}
                                  className="mt-2 rounded-lg shadow-md"
                                />
                              )}
                            </li>
                          ))}
                        </ol>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </div>
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Documentation;
