import { useEffect, useState, useCallback } from "react";
import { doc, getDoc } from "firebase/firestore";
import TitleCard from "../components/Cards/TitleCard";
import Layout from "../containers/Layout";
import { auth, db } from "../firebase";
import "../index.css";
import Loader from "../utils/Loader";
import LanguagePreferences from "../components/ProfilePage/LanguagePreferences";
import CompanyInfo from "../components/ProfilePage/CompanyInfo";
import { readOnlyStyle } from "../styles/ProfileStyles";
import EditButton from "../components/ProfilePage/EditButton";
import { getSelectedCompanyId } from "../utils/companyStorage";
import MessagesComponent from "../components/ProfilePage/MessagesComponent";
import validatePayment from "../utils/ValidatePayment";

function Profile() {
  const [userEmail, setUserEmail] = useState("");
  const [userDisplayName, setUserDisplayName] = useState("");
  const [companyDomain, setCompanyDomain] = useState("");
  const [numberOfEmployees, setNumberOfEmployees] = useState("");
  const [currentUserUid, setCurrentUserUid] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [message, setMessage] = useState(null);
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
      fetchUserData(firebaseUser);
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = useCallback(async (firebaseUser) => {
    if (firebaseUser) {
      const userUid = await getSelectedCompanyId(firebaseUser.uid);
      setCurrentUserUid(userUid);

      const userDocRef = doc(db, "companies", userUid);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        const userData = userDocSnapshot.data();
        setUserEmail(userData.email);
        setUserDisplayName(userData.companyName);
        setCompanyDomain(userData.companyDomain);
        setNumberOfEmployees(userData.numberOfEmployees);
      } else {
        console.error("No user data found for UID " + userUid);
      }

      const hasPaid = await validatePayment(firebaseUser.uid);
      if (!hasPaid) {
        window.location.href = "/onboarding";
      } else {
        setIsLoading(false); // Set loading to false after fetching user data
      }
    } else {
      window.location.href = "/signin";
    }
  }, []);

  const handleEditClick = (section) => {
    setIsEditing(true);
    setActiveSection(section);
    setMessage(null);
  };

  const handleUpdateClick = () => {
    setIsEditing(false);
    setActiveSection(null);
    setMessage("Successfully updated!");
  };

  const handleExitClick = () => {
    setIsEditing(false);
    setActiveSection(null);
    setMessage("Exited without saving changes.");
  };

  return (
    <Layout userDisplayName={userDisplayName} userEmail={userEmail}>
      <MessagesComponent userUid={currentUserUid} isLoading={isLoading} />

      <Loader
        loading={isLoading}
        size={50}
        color={"#123abc"}
        loadingText={"Loading Profile"}
      >
        <TitleCard title="Company Profile" topMargin="mt-2">
          <CompanyInfo
            userEmail={userEmail}
            name={userDisplayName}
            domain={companyDomain}
            numberOfEmployees={numberOfEmployees}
            isEditing={isEditing}
            activeSection={activeSection}
            handleEditClick={handleEditClick}
            readOnlyStyle={readOnlyStyle}
          />

          <LanguagePreferences
            isEditing={isEditing}
            activeSection={activeSection}
            handleEditClick={handleEditClick}
            readOnlyStyle={readOnlyStyle}
          />

          <EditButton
            isEditing={isEditing}
            handleUpdateClick={handleUpdateClick}
            handleExitClick={handleExitClick}
            message={message}
          />
        </TitleCard>
      </Loader>
    </Layout>
  );
}

export default Profile;
