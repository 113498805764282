import React from "react";
import { motion } from "framer-motion";
import { itemVariants } from "./AnimationsVariants";

const Introduction = () => {
  return (
    <motion.div
      className="text-center p-8"
      variants={itemVariants}
      initial="hidden"
      animate="visible"
    >
      <h1 className="text-3xl font-bold text-blue-900 mb-6">
        Welcome to WorkSense
      </h1>
      <p className="text-lg mb-4 text-gray-800 leading-relaxed">
        We're thrilled to have you here!
      </p>
      <p className="text-lg mb-4 text-gray-800 leading-relaxed">
        We’re here to guide you every step of the way and ensure you get the most out of our platform. 
      </p>
      <p className="text-lg mb-2 text-gray-800 leading-relaxed">
        Let's get started!
      </p>
    </motion.div>
  );
};

export default Introduction;
