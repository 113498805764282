import React from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { BsFillPersonCheckFill, BsExclamationDiamondFill, BsGraphUp, BsArrowRight } from 'react-icons/bs';
import Loader from '../../utils/Loader';

const InsightTile = ({ title, description, type }) => {
  const navigate = useNavigate();
  const cardStyles = `rounded-lg p-4 shadow flex flex-col justify-between space-y-2 hover:shadow-lg transition-shadow duration-200`;
  const getTypeStyles = () => {
    switch (type) {
      case 'positive':
        return `${cardStyles} bg-green-100 text-green-800`;
      case 'negative':
        return `${cardStyles} bg-red-100 text-red-800`;
      case 'neutral':
        return `${cardStyles} bg-yellow-100 text-yellow-800`;
      default:
        return cardStyles;
    }
  };

  const getIcon = (type) => {
    switch (type) {
      case 'positive':
        return <BsFillPersonCheckFill className="text-green-500" size={24} />;
      case 'neutral':
        return <BsGraphUp className="text-yellow-500" size={24} />;
      case 'negative':
        return <BsExclamationDiamondFill className="text-red-500" size={24} />;
      default:
        return null;
    }
  };

  return (
    <motion.div
      className={getTypeStyles()}
      whileHover={{ scale: 1.02 }}
      layout
      initial={{ opacity: 0.9 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.1 }}
    >
      <div>
        <div className="flex items-start space-x-2">
          <div>{getIcon(type)}</div>
          <div>
            <p className="font-semibold">{title}</p>
            <p className="text-sm">{description}</p>
          </div>
        </div>
      </div>
      <div 
        className="pt-2 border-t mt-2 border-gray-200"
        onClick={() => navigate('/insights')}
        role="button" 
        tabIndex={0}
      >
        <p className="text-blue-600 hover:text-blue-800 flex items-center font-semibold text-sm cursor-pointer">
          View More <BsArrowRight className="ml-1" />
        </p>
      </div>
    </motion.div>
  );
};

const HighlightedInsights = ({ scoreMetadata }) => {
  if (!scoreMetadata || scoreMetadata.length === 0) {
    return <Loader loading={true} size={30} color={"#123abc"} loadingText={"Generating Insights"}  />;
  }

  // Filter out the burnout score from consideration for these insights
  const filteredMetadata = scoreMetadata.filter(item => item.score_type !== "burnoutScore");

  const toFixedSafe = (value, digits = 2) => {
    const num = parseFloat(value);
    return isNaN(num) ? "0.00" : num.toFixed(digits);
  };

  // Calculate distribution percentages, reversing logic for negative insights for burnoutScore, but it's now excluded
  const distributionPercentage = (item, type) => {
    const { very_positive, positive, neutral, negative, very_negative } = item.distribution;
    const total = very_positive + positive + neutral + negative + very_negative;

    if (total === 0) return 0;

    const percentage = type === "negative" ? ((negative + very_negative) / total) * 100 : ((very_positive + positive) / total) * 100;
    return percentage ? percentage : 0;
  };

  // Finding insights based on score and distribution
  const lowestScore = filteredMetadata.reduce((acc, curr) => acc.score < curr.score ? acc : curr);
  const highestScore = filteredMetadata.reduce((acc, curr) => acc.score > curr.score ? acc : curr);

  const mostConcerningDistribution = filteredMetadata.reduce((acc, curr) => 
    distributionPercentage(acc, "negative") > distributionPercentage(curr, "negative") ? acc : curr);
  const mostPositiveDistribution = filteredMetadata.reduce((acc, curr) => 
    distributionPercentage(acc, "positive") > distributionPercentage(curr, "positive") ? acc : curr);
  
  const mostConcerningPercentage = toFixedSafe(distributionPercentage(mostConcerningDistribution, "negative"));
  const mostPositivePercentage = toFixedSafe(distributionPercentage(mostPositiveDistribution, "positive"));

  const insights = [
    {
      title: `Highest Score in ${highestScore.score_type.replace(/([A-Z])/g, ' $1')}`,
      description: `Excellent performance in ${highestScore.score_type.replace(/([A-Z])/g, ' $1').toLowerCase()}, with a score of ${toFixedSafe(highestScore.score)}. It's crucial to maintain or exceed this standard.`,
      type: 'positive',
    },
    {
      title: `Most Positive Distribution in ${mostPositiveDistribution.score_type.replace(/([A-Z])/g, ' $1')}`,
      description: `An encouraging ${mostPositivePercentage}% of responses are positive or very positive in ${mostPositiveDistribution.score_type.replace(/([A-Z])/g, ' $1').toLowerCase()}, highlighting strengths and high morale.`,
      type: 'positive',
    },
    {
      title: `Lowest Score in ${lowestScore.score_type.replace(/([A-Z])/g, ' $1')}`,
      description: `Significant improvement needed with a score of ${toFixedSafe(lowestScore.score)}. Strategies to address this are essential.`,
      type: 'negative',
    },
    {
      title: `Most Concerning Distribution in ${mostConcerningDistribution.score_type.replace(/([A-Z])/g, ' $1')}`,
      description: `A concerning ${mostConcerningPercentage}% of responses are negative or very negative in ${mostConcerningDistribution.score_type.replace(/([A-Z])/g, ' $1').toLowerCase()}, indicating areas needing immediate improvement.`,
      type: 'negative',
    },
  ];

  return (
    <div className="my-8">
      <h3 className="text-lg font-semibold mb-4">Highlighted Insights</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {insights.map((insight, index) => (
          <InsightTile key={index} {...insight} />
        ))}
      </div>
    </div>
  );
};

export default HighlightedInsights;