import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import * as Constants from '../../utils/Constants';
import { containerVariants, itemVariants } from './AnimationsVariants';

const IntegrationsList = ({
  selectedIntegrations,
  customIntegrations,
  setSelectedIntegrations,
  setCustomIntegrations,
  errors,
}) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [customList, setCustomList] = useState([]);
  const integrationsRef = useRef(null);

  const handleIntegrationChange = (integration) => {
    if (selectedIntegrations.includes(integration)) {
      setSelectedIntegrations(
        selectedIntegrations.filter((item) => item !== integration)
      );
    } else {
      setSelectedIntegrations([...selectedIntegrations, integration]);
    }
  };

  const handleScroll = () => {
    if (integrationsRef.current) {
      const { scrollHeight, clientHeight, scrollTop } = integrationsRef.current;
      setShowScrollButton(scrollHeight > clientHeight && scrollTop < scrollHeight - clientHeight);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const newIntegration = e.target.value.trim();
      if (newIntegration && !customList.includes(newIntegration)) {
        setCustomList([...customList, newIntegration]);
        setCustomIntegrations(''); // Clear input field
      }
    }
  };

  const handleDelete = (index) => {
    const newList = [...customList];
    newList.splice(index, 1);
    setCustomList(newList);
  }

  return (
    <>
      <motion.h2
        className="text-2xl font-semibold mb-8 text-center text-blue-800"
        variants={itemVariants}
      >
        Provide Your Integrations of Interest
      </motion.h2>
      <motion.p
        className="text-base mb-6 text-center text-gray-600"
        variants={itemVariants}
      >
        To ensure that we seamlessly integrate with your existing systems, please let us know which platforms you currently use. This information helps us tailor our solutions to your needs and enhance your overall experience with our service.
      </motion.p>

      <motion.div
        ref={integrationsRef}
        className={`grid grid-cols-2 sm:grid-cols-3 gap-8 p-4 mb-6 max-h-80 overflow-y-auto ${
          errors.integrations ? 'border-red-500 border-2 shadow-lg' : ''
        }`}
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        style={{ overflowX: 'hidden' }}
        onScroll={handleScroll}
      >
        {Object.keys(Constants.INTEGRATION_ICONS).map((integration) => (
          <motion.div
            key={integration}
            whileHover={{ scale: 1.05 }}
            className={`flex flex-col items-center p-6 rounded-lg shadow-lg cursor-pointer transition duration-300 ${
              selectedIntegrations.includes(integration)
                ? 'bg-blue-50 border-blue-400 border-2  shadow-blue-300'
                : 'bg-white'
            }`}
            onClick={() => handleIntegrationChange(integration)}
            variants={itemVariants}
          >
            <img
              src={Constants.INTEGRATION_ICONS[integration]}
              alt={integration}
              className="w-16 h-16 mb-2"
            />
            <span className="text-sm font-semibold text-gray-800">
              {integration}
            </span>
          </motion.div>
        ))}
      </motion.div>

      {/* Error message at the bottom */}
      {errors.integrations && (
        <p className="text-red-600 text-center">At least one integration is required</p>
      )}

      <motion.div className="mt-8" variants={itemVariants}>
        <label className="block mb-2 font-semibold text-blue-800 text-sm">
          Any integrations you don't see, but would like? (Press Enter to add)
        </label>
        <div className="flex flex-col items-start">
          <input
            type="text"
            className="w-full px-4 py-3 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-lg rounded-lg"
            value={customIntegrations}
            onChange={(e) => setCustomIntegrations(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Enter other integrations and press Enter"
          />
          {/* Display custom integrations list */}
          {customList.length > 0 && (
            <div className="mt-4 flex flex-wrap gap-2">
              {customList.map((integration, index) => (
                <span
                  key={index}
                  className="bg-blue-100 text-blue-800 py-1 px-3 rounded-full text-sm"
                >
                  {integration}
                  <button
                    onClick={() => handleDelete(index)}
                    className="ml-2 text-blue-600 hover:text-blue-800 focus:outline-none"
                  >
                &times;
              </button>
                </span>
              ))}
            </div>
          )}
        </div>
      </motion.div>

      {/* Sticky Scroll Button */}
      {showScrollButton && (
        <div className="fixed bottom-4 right-4 z-20">
          <button
            onClick={() => integrationsRef.current.scrollTo({ top: integrationsRef.current.scrollHeight, behavior: 'smooth' })}
            className="bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition duration-300"
            aria-label="Scroll to Bottom"
          >
            Scroll Down
          </button>
        </div>
      )}
    </>
  );
};

export default IntegrationsList;
