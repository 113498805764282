import Subtitle from "../Typography/Subtitle";

function TitleCard({ title, children, topMargin, TopSideButtons }) {
  return (
    <div
      data-theme="emerald"
      className={"card w-full p-6 shadow-xl " + (topMargin || "mt-6")}
    >
      <Subtitle
        styleClass={TopSideButtons ? "flex justify-between items-center" : ""}
      >
        <span>{title}</span>
        {TopSideButtons && <div>{TopSideButtons}</div>}
      </Subtitle>

      <div className="divider my-2"></div>

      <div
        data-theme="emerald"
        className="flex flex-col h-full justify-between"
      >
        {children}
      </div>
    </div>
  );
}

export default TitleCard;
