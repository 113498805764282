import Bars3Icon from "@heroicons/react/24/outline/Bars3Icon";
import { signOut } from "firebase/auth";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import { createImageFromInitials } from "../utils/DisplayImageGenerator";

function Header({ userDisplayName, userEmail }) {
  function logoutUser() {
    localStorage.clear();
    window.location.href = "/";
  }

  async function handleLogout() {
    try {
      await signOut(auth);
      window.location.href = "/signin";
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  }

  return (
    <>
      <div className="navbar  flex justify-between bg-base-100  z-10 shadow-md ">
        {/* Menu toogle for mobile view or small screen */}
        <div className="">
          <label
            htmlFor="my-drawer-2"
            className="btn btn-grey drawer-button lg:hidden"
          >
            <Bars3Icon className="h-5 inline-block w-5" />
          </label>
          <h1 className="text-2xl font-semibold ml-2">Dashboard</h1>
        </div>

        <div className="order-last">
          <div className="dropdown dropdown-end ml-4">
            <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img
                  src={
                    userDisplayName
                      ? createImageFromInitials(500, userDisplayName)
                      : createImageFromInitials(500, userEmail)
                  }
                  alt="profile pic"
                />
              </div>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li className="justify-between">
                <Link to={"/Profile"}>
                  Profile Settings
                  <span className="badge">New</span>
                </Link>
              </li>
              <div className="divider mt-0 mb-0"></div>
              <li>
                <a onClick={handleLogout}>Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
