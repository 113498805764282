import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterListIcon from "@mui/icons-material/FilterList";
import {
  Chip,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { doc, getDoc } from "firebase/firestore";
import { Button } from "flowbite-react";
import React, { useCallback, useEffect, useState } from "react";
import { db } from "../firebase";
import * as Constants from "../utils/Constants";
import { getApiUrl } from "../utils/apiConfig";

const FilterOptions = ({ user, onFilterChange }) => {
  const [isExpanded, setExpanded] = useState(false);
  const [filters, setFilters] = useState({
    scoreType: Constants.SENTIMENT_SCORE_TYPE,
    dateRange: "Last 30 Days",
    gender: "",
    team: "",
    ethnicity: "",
    marital_status: "",
    location: "",
    division: "",
    department: "",
    job_family_name: "",
    age: "",
    start_date: null,
    end_date: null,
    generation: "",
    dob_start: null,
    dob_end: null,
  });

  const filterOptionsConfig = [
    { key: "scoreType", label: "Score Type" },
    { key: "dateRange", label: "Date Range" },
    { key: "gender", label: "Gender" },
    { key: "team", label: "Team" },
    { key: "ethnicity", label: "Ethnicity" },
    { key: "marital_status", label: "Marital Status" },
    { key: "location", label: "Location" },
    { key: "division", label: "Division" },
    { key: "department", label: "Department" },
    { key: "job_family_name", label: "Job Family" },
    { key: "age", label: "Age" },
  ];

  if (user.uid === "cURYZcMmDtYmcbrlQsWXSCi3O7C3") {
    filterOptionsConfig.push({ key: "generation", label: "Generation" });
  }

  const [filterOptions, setFilterOptions] = useState({
    scoreType: ["Select Score Type"],
    dateRange: [
      "Select Date Range",
      "Last Week",
      "Last 2 Weeks",
      "Last 30 Days",
      "Last 60 Days",
      "Last 90 Days",
      "Custom Date Range",
    ],
    gender: ["Select Gender"],
    team: ["Select Team"],
    ethnicity: ["Select Ethnicity"],
    marital_status: ["Select Marital Status"],
    location: ["Select Location"],
    division: ["Select Division"],
    department: ["Select Department"],
    job_family_name: ["Select Job Family"],
    age: ["Select Age", "18-27", "28-43", "44-59"],
    generation: [
      "Select Generation",
      "Generation X",
      "Millennials",
      "Generation Z",
    ],
  });
  const [sandboxUserQueryParameter, setSandboxUserQueryParameter] =
    useState("");

  const toggleCollapse = () => {
    setExpanded(!isExpanded);
  };

  const handleFilterChange = (filter, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: value,
    }));

    if (filter === "dateRange") {
      const endDate = dayjs().endOf("day");
      let startDate = dayjs().startOf("day");

      switch (value) {
        case "Last Week":
          startDate = dayjs().subtract(1, "week").startOf("day");
          break;
        case "Last 2 Weeks":
          startDate = dayjs().subtract(2, "weeks").startOf("day");
          break;
        case "Last 30 Days":
          startDate = dayjs().subtract(30, "days").startOf("day");
          break;
        case "Last 60 Days":
          startDate = dayjs().subtract(60, "days").startOf("day");
          break;
        case "Last 90 Days":
          startDate = dayjs().subtract(90, "days").startOf("day");
          break;
        case "Custom Date Range":
          return;
        default:
          console.error(`Unhandled date range: ${value}`);
          return;
      }
      setFilters((prevFilters) => ({
        ...prevFilters,
        start_date: startDate,
        end_date: endDate,
      }));
    } else if (filter === "generation") {
      let dobStart = "";
      let dobEnd = "";
      switch (value) {
        case "Generation X":
          dobStart = "1965-01-01";
          dobEnd = "1979-12-31";
          break;
        case "Millennials":
          dobStart = "1980-01-01";
          dobEnd = "1994-12-31";
          break;
        case "Generation Z":
          dobStart = "1995-01-01";
          dobEnd = "2009-12-31";
          break;
        default:
          console.error(`Unhandled generation: ${value}`);
          return;
      }
      setFilters((prevFilters) => ({
        ...prevFilters,
        dob_start: dobStart,
        dob_end: dobEnd,
      }));
    }
  };

  const handleDateChange = (key, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const clearFilters = () => {
    setFilters({
      scoreType: Constants.SENTIMENT_SCORE_TYPE,
      dateRange: "Last 30 Days",
      gender: "",
      team: "",
      ethnicity: "",
      marital_status: "",
      location: "",
      division: "",
      department: "",
      job_family_name: "",
      start_date: null,
      end_date: null,
      age: "",
      generation: "",
      dob_start: null,
      dob_end: null,
    });
  };

  const fetchData = useCallback(async () => {
    try {
      const globalFilterDocRef = doc(
        db,
        Constants.FILTER_TABLE_NAME,
        Constants.OPTION_DOCUMENT_NAME
      );
      const globalFilterData = await getDoc(globalFilterDocRef);

      const idToken = await user.firebaseUser.getIdToken();
      const endpointUrl = getApiUrl("get-filters");
      const url = `${endpointUrl}?company_uuid=${user.uid}${
        user.isSandboxUser ? "&demo=True" : ""
      }`;
      console.log(url);
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
        mode: "cors",
      })
        .then((response) => response.json())
        .then((response) => {
          if (response) {
            let locations = response.locations || [];
            if (user.uid === "aGlJ700yjLO4OtmDvx5GwvmOH5n1") {
              locations = ["Belgrade"];
            }

            setFilterOptions((prevOptions) => ({
              ...prevOptions,
              scoreType: [
                "Select Score Type",
                ...new Set(globalFilterData.data().scoreType || []),
              ],
              gender: ["Select Gender", ...new Set(response.genders || [])],
              team: [
                "Select Team",
                ...new Set(response.teams.map((team) => team.team_name) || []),
              ],
              ethnicity: [
                "Select Ethnicity",
                ...new Set(response.ethnicities || []),
              ],
              marital_status: [
                "Select Marital Status",
                ...new Set(response.marital_statuses || []),
              ],
              department: [
                "Select Department",
                ...new Set(response.departments || []),
              ],
              division: [
                "Select Division",
                ...new Set(response.divisions || []),
              ],
              job_family_name: [
                "Select Job Family",
                ...new Set(response.job_family_names || []),
              ],
              location: ["Select Location", ...new Set(locations)],
            }));
          }
        })
        .catch((error) => {
          console.error("Error fetching filter data:", error);
        });
    } catch (error) {
      console.error("Error fetching filter data:", error);
    }
  }, [user]);

  useEffect(() => {
    applyFilters();
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const applyFilters = () => {
    if (!filters.start_date && filters.end_date) {
      alert("You must select a start date for the specified date range.");
    } else if (filters.start_date && !filters.end_date) {
      alert("You must select an end date for the specified date range.");
    } else if (
      filters.start_date &&
      filters.end_date &&
      filters.start_date > filters.end_date
    ) {
      alert("Start date must be before end date.");
    } else if (filters.dob_start && filters.dob_end) {
      const filtersToSend = {
        ...filters,
        dob_start: filters.dob_start || undefined,
        dob_end: filters.dob_end || undefined,
      };
      onFilterChange(filtersToSend);
    }
    onFilterChange(filters);
  };

  const createDropdown = (key, label, options, value, handleChange) => (
    <FormControl variant="outlined" className="w-full">
      <InputLabel id={`${key}-label`}>{label}</InputLabel>
      <Select
        labelId={`${key}-label`}
        id={key}
        value={options.includes(value) ? value : ""}
        onChange={(e) => handleChange(key, e.target.value)}
        label={label}
      >
        <MenuItem value="" disabled>
          {options[0]}
        </MenuItem>
        {options.slice(1).map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const countSelectedFilters = () => {
    const excludedKeys = ["start_date", "end_date", "dob_start", "dob_end"];
    return Object.entries(filters).filter(
      ([key, value]) => value && !excludedKeys.includes(key)
    ).length;
  };

  const renderSelectedFilterLabels = () => {
    const excludedKeys = ["start_date", "end_date", "dob_start", "dob_end"];
    const selectedFilters = Object.entries(filters)
      .filter(([key, value]) => value && !excludedKeys.includes(key))
      .map(([key, value]) => {
        const filterLabel = filterOptionsConfig.find(
          (filter) => filter.key === key
        )?.label;
        return (
          <Chip
            key={key}
            label={`${filterLabel}: ${value}`}
            className="mr-2 mb-2 bg-blue-600 text-white rounded-lg"
            color="primary"
          />
        );
      });

    return selectedFilters.length > 0 ? (
      <div
        className="flex flex-wrap gap-2 ml-4 items-center"
        style={{ alignItems: "center" }}
      >
        {selectedFilters}
      </div>
    ) : null;
  };

  const renderSelectedFilters = () => {
    const excludedKeys = ["start_date", "end_date", "dob_start", "dob_end"];
    const selectedFilters = Object.entries(filters)
      .filter(([key, value]) => value && !excludedKeys.includes(key))
      .map(([key, value]) => {
        const filterLabel = filterOptionsConfig.find(
          (filter) => filter.key === key
        )?.label;
        return (
          <Chip
            key={key}
            label={`${filterLabel}: ${value}`}
            onDelete={() => handleFilterChange(key, "")}
            className="mr-2 mb-2 bg-gray-200 text-gray-800 rounded-lg"
            color="default"
          />
        );
      });

    return selectedFilters.length > 0 ? (
      <div className="mb-4">
        <h3 className="text-lg font-medium mb-2">Selected Filters</h3>
        <div className="flex flex-wrap gap-2">{selectedFilters}</div>
      </div>
    ) : null;
  };

  return (
    <div className="mb-4 bg-white rounded-lg shadow-md">
      <div
        className="flex items-center justify-between cursor-pointer p-4 border-b border-gray-200"
        onClick={toggleCollapse}
      >
        <div className="flex items-center" style={{ alignItems: "center" }}>
          <FilterListIcon className="mr-2 text-blue-600" />
          <h2 className="text-2xl font-semibold text-gray-800">Filters</h2>
          {countSelectedFilters() > 0 && (
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-600 text-white">
              {countSelectedFilters()}
            </span>
          )}
          {!isExpanded && renderSelectedFilterLabels()}
        </div>
        <IconButton>
          <ExpandMoreIcon
            className={`transform transition-transform ${
              isExpanded ? "rotate-180" : ""
            } text-gray-600`}
          />
        </IconButton>
      </div>
      <Collapse in={isExpanded}>
        <div className="p-4">
          {isExpanded && renderSelectedFilters()}
          <Grid container spacing={2}>
            {filterOptionsConfig.map(({ key, label }) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                {key === "dateRange" && filters[key] === "Custom Date Range" ? (
                  <div>
                    {createDropdown(
                      key,
                      label,
                      filterOptions[key],
                      filters[key],
                      handleFilterChange
                    )}
                    <div className="mt-4 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                      <DatePicker
                        id="start_date"
                        label="Start Date"
                        value={filters.start_date}
                        onChange={(newValue) =>
                          handleDateChange("start_date", newValue)
                        }
                        renderInput={(params) => <TextField {...params} />}
                        className="w-full"
                      />
                      <DatePicker
                        id="end_date"
                        label="End Date"
                        value={filters.end_date}
                        onChange={(newValue) =>
                          handleDateChange("end_date", newValue)
                        }
                        renderInput={(params) => <TextField {...params} />}
                        className="w-full"
                      />
                    </div>
                  </div>
                ) : (
                  createDropdown(
                    key,
                    label,
                    filterOptions[key],
                    filters[key],
                    handleFilterChange
                  )
                )}
              </Grid>
            ))}
          </Grid>
          <div className="mt-6 flex justify-end space-x-4">
            <Button color="gray" onClick={clearFilters}>
              Clear Filters
            </Button>
            <Button
              onClick={applyFilters}
              color="primary"
              variant="contained"
              className="bg-green-600 hover:bg-green-700 text-white"
            >
              Apply Filters
            </Button>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default FilterOptions;
