import React from "react";
import { motion } from "framer-motion";
import { itemVariants } from "./AnimationsVariants";

const CompanyName = ({ companyName, setCompanyName, errors }) => {
  const handleCompanyNameChange = (e) => {
    const value = e.target.value;
    setCompanyName(value);
  };

  const inputErrorStyle = {
    borderColor: "red",
    boxShadow: "0 0 0 1px red",
  };

  return (
    <>
      <motion.h2
        className="text-2xl font-semibold mb-8 text-center text-blue-800"
        variants={itemVariants}
      >
        Set Up Your Profile
      </motion.h2>
      <p className="text-lg mb-4 text-gray-800 leading-relaxed text-center">
        To get started, please provide your company name.
      </p>
      <motion.div className="mb-6" variants={itemVariants}>
        <input
          type="text"
          className={`w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg ${
            errors.companyName ? "border-red-500 border-1" : ""
          }`}
          style={errors.companyName ? inputErrorStyle : {}}
          value={companyName}
          onChange={handleCompanyNameChange}
          placeholder="e.g., Acme Inc."
        />
        {errors.companyName && (
          <p className="text-red-500 text-sm mt-2 text-center">
            {errors.companyName}
          </p>
        )}
      </motion.div>
    </>
  );
};

export default CompanyName;
