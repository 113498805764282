import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import Loader from "../../utils/Loader";
import BarData from "../BarData";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function TeamCharts({ filters, data }) {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 p-4">
      <div className="w-full h-[400px]">
        <Loader
          loading={!data.scoreByTopData}
          componentLoading={true}
          size={30}
          color={"#4a90e2"}
          loadingText={"Top Team Data"}
        >
          <BarData
            title={`Top ${filters.scoreType} Scores`}
            dataSet={data.scoreByTopData || []}
            color="#4a90e2"
          />
        </Loader>
      </div>
      <div className="w-full h-[400px]">
        <Loader
          loading={!data.scoreByBottomData}
          componentLoading={true}
          size={30}
          color={"#e25c4a"}
          loadingText={"Bottom Team Data"}
        >
          <BarData
            title={`Bottom ${filters.scoreType} Scores`}
            dataSet={data.scoreByBottomData || []}
            color="#e25c4a"
          />
        </Loader>
      </div>
    </div>
  );
}
