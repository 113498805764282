import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithRedirect,
  getRedirectResult,
  sendPasswordResetEmail,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import googleLogo from "../images/logos/googleLogo.png";
import * as Constants from "../utils/Constants";
import Loader from "../utils/Loader";

function handleErrorMessage(error, setError) {
  const errorCode = error.code;
  let errorMessage = "";

  if (errorCode === "auth/user-not-found") {
    errorMessage = "Invalid email or password";
  } else if (errorCode === "auth/wrong-password") {
    errorMessage = "Invalid email or password";
  } else if (errorCode === "auth/popup-closed-by-user") {
    errorMessage = "Sign-in cancelled";
  } else {
    errorMessage = "An error occurred";
  }

  setError(errorMessage);
}

export const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setLoading(true);
    getRedirectResult(auth)
      .then((result) => {
        if (result?.user) {
          // Handle user initialization after redirect sign-in
          initUser(result.user).finally(() => {
            checkOnboardingStatus(result.user);
          });
        } else {
          // Check if the user is already logged in
          onAuthStateChanged(auth, (user) => {
            if (user) {
              checkOnboardingStatus(user);
            } else {
              setLoading(false);
            }
          });
        }
      })
      .catch((error) => {
        handleErrorMessage(error, setError);
        setLoading(false);
      });
  }, []);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      checkOnboardingStatus(auth.currentUser); 
    } catch (error) {
      handleErrorMessage(error, setError);
    }
  };

  const handleGoogleSignIn = async () => {
    setLoading(true);

    const provider = new GoogleAuthProvider();
    try {
      await signInWithRedirect(auth, provider);
    } catch (error) {
      handleErrorMessage(error, setError);
      setLoading(false);
    }
  };

  const resetPassword = async (email) => {
    if (!email) {
      setError("Please provide your email address.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert("A password reset link has been sent to your email.");
    } catch (error) {
      setError("Failed to send reset password email. " + error.message);
    }
  };

  async function initUser(user) {
    const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, user.uid);
    const docSnap = await getDoc(userDocRef);

    if (!docSnap.exists()) {
      const userData = {
        name: user.displayName,
        email: user.email,
        isDemoUser: false,
      };
      await setDoc(userDocRef, userData);
    }
  }

  if (isAuthenticated) {
    window.location.href = "/dashboard"; 
    return null; 
  }

  const checkOnboardingStatus = async (user) => {
    const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, user.uid);
    const docSnap = await getDoc(userDocRef);

    if (docSnap.exists() && !docSnap.data().isOnboarded) {
      window.location.href = "/onboarding";
    } else {
      setIsAuthenticated(true);
      setLoading(false);
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900 min-h-screen flex flex-col justify-center items-center px-4">
      <Loader loading={loading} size={50} color={"#123abc"}>
        <div className="w-full max-w-md p-6 space-y-8 bg-white rounded-lg shadow-xl dark:bg-gray-800">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
            Sign in to Worksense
          </h2>
          <form className="mt-8 space-y-6" onSubmit={handleSignIn}>
            <div>
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="name@company.com"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your password
              </label>
              <input
                type="password"
                name="password"
                id="password"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="remember"
                  aria-describedby="remember"
                  name="remember"
                  type="checkbox"
                  className="w-4 h-4 border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="remember"
                  className="font-medium text-gray-500 dark:text-gray-400"
                >
                  Remember this device
                </label>
              </div>
              {/* <a
                onClick={() => resetPassword(email)}
                className="ml-auto text-sm font-medium text-blue-600 hover:underline dark:text-blue-500"
              >
                Lost Password?
              </a> */}
            </div>
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <button
              type="submit"
              className="w-full px-5 py-3 text-base font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 sm:w-auto dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Login to your account
            </button>

            {/* <div className="text-sm font-medium text-gray-900 dark:text-white">
              Not registered yet?{" "}
              <a
                href="/signup"
                className="text-blue-600 hover:underline dark:text-blue-500"
              >
                Create account
              </a>
            </div> */}

            {/* <div className="flex items-center justify-center">
              <span className="text-gray-500">Or sign in with</span>
              <button
                type="button"
                onClick={handleGoogleSignIn}
                className="ml-2 p-1.5 border border-gray-300 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <img src={googleLogo} alt="Google Logo" className="h-6 w-6" />
              </button>
            </div> */}
          </form>
        </div>
      </Loader>
      <div className="mt-8 text-center">
        <span className="text-gray-600 dark:text-gray-400 text-sm">
          Interested in our services?
        </span>
        <a
          href="https://cal.com/arshanahmad/30min"
          className="ml-2 px-6 py-2 font-medium text-white transition duration-300 ease-in-out bg-gradient-to-r from-teal-500 to-blue-600 hover:bg-gradient-to-bl focus:outline-none focus:ring-4 focus:ring-blue-300 rounded-full shadow-lg hover:shadow-xl transform hover:-translate-y-1"
        >
          Contact sales
        </a>
      </div>
    </section>
  );
};
