import React, { useState } from "react";
import { storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL, listAll } from "firebase/storage";
import { getAuth } from "firebase/auth";

const CSVButton = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(""); // State for upload status

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (file) {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const companyUid = user.uid; // Assuming user's UID is the company UID
        const folderPath = `CSV/${companyUid}`;
        const storageRef = ref(storage, folderPath);

        // Ensure the folder exists
        const folderList = await listAll(storageRef).catch(() => null);
        if (folderList === null) {
          console.error("Folder does not exist and couldn't be created.");
          return;
        }

        const formatDate = (date) => {
          const d = new Date(date);
          const month = ("0" + (d.getMonth() + 1)).slice(-2);
          const day = ("0" + d.getDate()).slice(-2);
          const year = d.getFullYear();
          return `${month}-${day}-${year}`;
        };

        const dateSuffix = formatDate(new Date());
        const fileNameWithDate = `${file.name.split('.').slice(0, -1).join('.')}_${dateSuffix}.${file.name.split('.').pop()}`;
        const fileRef = ref(storage, `${folderPath}/${fileNameWithDate}`);
        const uploadTask = uploadBytesResumable(fileRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadStatus(`Upload is ${progress.toFixed(2)}% done`);
          },
          (error) => {
            console.error("Upload failed:", error);
            setUploadStatus("Upload failed");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              setUploadStatus("Upload completed successfully");
            });
          }
        );
      } else {
        console.error("User is not authenticated.");
        setUploadStatus("User is not authenticated.");
      }
    }
  };

  return (
    <div className="flex flex-col space-y-2 items-center">
      <input
        className="block w-full text-sm text-green-800 border border-green-800 rounded-lg cursor-pointer dark:text-green-500 focus:outline-none dark:bg-gray-700 dark:border-gray-600"
        id="file_input"
        type="file"
        onChange={handleFileChange}
        accept=".csv"
      />
      <div className="self-end">
        <button
          type="button"
          className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
          onClick={handleUpload}
        >
          Upload CSV
        </button>
      </div>
      {uploadStatus && <div className="mt-4 text-green-700">{uploadStatus}</div>}
    </div>
  );
};

export default CSVButton;
