const COMPANY_UID_KEY = "selectedCompanyId";

export const getSelectedCompanyId = (currentUserUid = "4KISCpZsWBd2xinV7d4M1P9kZFq2") => {
  if (currentUserUid !== "4KISCpZsWBd2xinV7d4M1P9kZFq2") {
    return currentUserUid;
  }

  return localStorage.getItem(COMPANY_UID_KEY) || "4KISCpZsWBd2xinV7d4M1P9kZFq2";
};

export const setSelectedCompanyId = (companyId) => {
  localStorage.setItem(COMPANY_UID_KEY, companyId);
};