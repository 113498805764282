export const mapScoreTypes = (scoreType) => {
  // Convert underscore_case to camelCase
  const normalizedScoreType = scoreType.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

  switch (normalizedScoreType) {
    case "managerEffectivenessScore":
      return "Manager Effectiveness";
    case "productivityScore":
      return "Productivity";
    case "engagementScore":
      return "Engagement";
    case "personalSatisfactionScore":
      return "Personal Satisfaction";
    case "confidenceScore":
      return "Confidence";
    case "positivityScore":
      return "Positivity";
    case "sentimentScore":
      return "Sentiment";
    case "leadershipSentimentScore":
      return "Leadership";
    case "recognitionScore":
      return "Recognition";
    case "empowermentScore":
      return "Empowerment";
    case "psychologicalSafetyScore":
      return "Psychological Safety";
    case "focusScore":
      return "Focus";
    case "energyScore":
      return "Energy";
    case "careerGrowthScore":
      return "Career Growth";
    case "motivationScore":
      return "Motivation";
    case "burnoutScore":
      return "Burnout";
    case "collaborationScore":
      return "Collaboration";
    case "belongingScore":
      return "Belonging";
    case "alignmentScore":
      return "Alignment";
    case "autonomyScore":
      return "Autonomy";
    case "communicationScore":
      return "Communication";
    case "physicalEnvironmentScore":
      return "Physical Environment";
    default:
      return scoreType; // Return the original scoreType if not matched
  }
};