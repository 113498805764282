import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import {
  FaBolt,
  FaBuilding,
  FaBullseye,
  FaBurn,
  FaChartBar,
  FaChartLine,
  FaComments as FaCommentsAlt,
  FaCompass,
  FaCrown,
  FaGem,
  FaHandshake,
  FaHeart,
  FaRocket,
  FaShieldAlt,
  FaSmile,
  FaSmile as FaSmileAlt,
  FaThumbsUp,
  FaTrophy,
  FaUnlockAlt,
  FaUserTie,
  FaUsers,
} from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Layout from "../containers/Layout";
import { checkAuth } from "../utils/AuthCheck";

const DefinitionGlossary = () => {
  const [expandedScoreType, setExpandedScoreType] = useState(null);

  const [user, setUser] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [isDemoUser, setIsDemoUser] = useState(true);
  const [authComplete, setAuthComplete] = useState(false);

  useEffect(() => {
    async function authenticateUser() {
      try {
        await checkAuth(setUser, setUserToken, setIsDemoUser);
        setAuthComplete(true);
      } catch (error) {
        console.error("Error during authentication:", error);
      }
    }

    authenticateUser();
  }, [user]);

  const scoreDefinitions = [
    {
      title: "Burnout Score",
      definition:
        "Measures the level of burnout, indicating stress and fatigue.",
      icon: <FaBurn size={30} />,
      details:
        "Burnout is a state of emotional, physical, and mental exhaustion caused by prolonged stress. It can lead to decreased productivity, increased absenteeism, and higher turnover rates. Factors contributing to burnout include heavy workloads, lack of support, and poor work-life balance.",
      calculation:
        "The burnout score is calculated by analyzing communication patterns, workload metrics from project management tools, and engagement levels across various employee platforms. Factors such as long working hours, minimal time off, and consistently high stress levels contribute to a higher burnout score.",
    },
    {
      title: "Energy Score",
      definition: "Assesses overall energy and vitality.",
      icon: <FaBolt size={30} />,
      details:
        "The energy score measures employees' overall sense of vigor and enthusiasm at work. High energy levels are associated with increased engagement, productivity, and job satisfaction. Factors influencing energy include physical health, mental well-being, and a positive work environment.",
      calculation:
        "The energy score is determined by evaluating metrics such as meeting participation, responsiveness to communication, and the overall tone and sentiment of interactions across various collaboration tools. Consistent high energy and enthusiasm contribute to a higher energy score.",
    },
    {
      title: "Psychological Safety Score",
      definition: "Measures team safety to take risks and speak up.",
      icon: <FaShieldAlt size={30} />,
      details:
        "Psychological safety refers to the belief that one can express ideas, concerns, or mistakes without fear of negative consequences. It fosters innovation, learning, and effective teamwork. Factors promoting psychological safety include trust, open communication, and supportive leadership.",
      calculation:
        "The psychological safety score is calculated by analyzing the frequency and nature of interactions, the diversity of opinions shared, and the responsiveness to feedback and concerns across communication channels. An environment that encourages open dialogue, active listening, and respectful disagreement contributes to a higher psychological safety score.",
    },
    {
      title: "Belonging Score",
      definition: "Measures the sense of inclusion and belonging.",
      icon: <FaHeart size={30} />,
      details:
        "Belonging is the feeling of being accepted, valued, and included within a team or organization. It contributes to employee engagement, retention, and overall well-being. Factors influencing belonging include inclusive practices, fair treatment, and a welcoming culture.",
      calculation:
        "The belonging score is determined by evaluating the frequency and quality of interactions among team members, participation in team events and discussions, and the use of inclusive language across communication platforms. Regular collaboration, equal opportunities for contribution, and a supportive team environment contribute to a higher belonging score.",
    },
    {
      title: "Positivity Score",
      definition: "Indicates overall positive outlook and attitude.",
      icon: <FaSmile size={30} />,
      details:
        "The positivity score assesses employees' general optimism and constructive mindset. A positive outlook can improve resilience, collaboration, and job performance. Factors promoting positivity include recognition, supportive relationships, and a focus on strengths and successes.",
      calculation:
        "The positivity score is calculated by analyzing the sentiment and language used in communications, the frequency of positive interactions and feedback, and the overall tone of discussions across collaboration tools. Consistent use of optimistic language, expressions of gratitude, and a focus on solutions contribute to a higher positivity score.",
    },
    {
      title: "Physical Environment Score",
      definition: "Assesses the quality of the physical workspace.",
      icon: <FaBuilding size={30} />,
      details:
        "The physical environment score evaluates the comfort, functionality, and safety of the workspace. A well-designed environment can enhance productivity, creativity, and employee well-being. Factors influencing the physical environment include ergonomics, lighting, temperature, and noise levels.",
      calculation:
        "The physical environment score is determined by analyzing data from smart office sensors, employee feedback on workspace satisfaction, and the utilization of ergonomic equipment and amenities. Optimal lighting, comfortable temperature, minimal noise distractions, and availability of necessary resources contribute to a higher physical environment score.",
    },
    {
      title: "Productivity Score",
      definition: "Measures effectiveness and output of work.",
      icon: <FaChartLine size={30} />,
      details:
        "The productivity score assesses employees' ability to efficiently complete tasks and achieve desired outcomes. High productivity is driven by factors such as clear goals, adequate resources, effective processes, and a supportive work environment.",
      calculation:
        "The productivity score is calculated by analyzing task completion rates, time management metrics, and the achievement of individual and team goals using data from project management and productivity tools. Consistent meeting of deadlines, efficient resource utilization, and high-quality deliverables contribute to a higher productivity score.",
    },
    {
      title: "Motivation Score",
      definition: "Evaluates drive and willingness to work.",
      icon: <FaRocket size={30} />,
      details:
        "The motivation score measures employees' intrinsic drive and enthusiasm for their work. Motivated employees are more engaged, committed, and likely to go above and beyond. Factors influencing motivation include meaningful work, recognition, growth opportunities, and a sense of purpose.",
      calculation:
        "The motivation score is determined by evaluating the level of initiative, participation in learning and development activities, and the pursuit of challenging goals using data from performance management systems and learning platforms. Proactive behavior, continuous skill improvement, and a strong sense of ownership contribute to a higher motivation score.",
    },
    {
      title: "Focus Score",
      definition: "Assesses ability to concentrate on tasks.",
      icon: <FaBullseye size={30} />,
      details:
        "The focus score evaluates employees' capacity to maintain attention and avoid distractions. High focus leads to increased productivity, accuracy, and efficiency. Factors promoting focus include clear priorities, minimized interruptions, and a conducive work environment.",
      calculation:
        "The focus score is calculated by analyzing the time spent on focused work, the frequency and duration of distractions, and the completion of high-priority tasks using data from time tracking and productivity tools. Longer periods of uninterrupted work, effective management of notifications, and consistent progress on key deliverables contribute to a higher focus score.",
    },
    {
      title: "Recognition Score",
      definition: "Measures how valued and appreciated employees feel.",
      icon: <FaTrophy size={30} />,
      details:
        "The recognition score assesses the extent to which employees feel acknowledged and appreciated for their contributions. Effective recognition boosts morale, motivation, and job satisfaction. It can include formal rewards, verbal praise, and meaningful feedback.",
      calculation:
        "The recognition score is determined by evaluating the frequency and quality of positive feedback, the use of recognition tools and programs, and the impact of recognition on employee sentiment using data from performance management and employee engagement platforms. Timely, specific, and meaningful recognition from peers and leaders contributes to a higher recognition score.",
    },
    {
      title: "Engagement Score",
      definition: "Assesses involvement and enthusiasm at work.",
      icon: <FaHandshake size={30} />,
      details:
        "The engagement score measures employees' emotional connection and commitment to their work and the organization. Engaged employees are more productive, innovative, and likely to stay with the company. Factors driving engagement include meaningful work, supportive relationships, and growth opportunities.",
      calculation:
        "The engagement score is calculated by analyzing participation in company events and initiatives, the level of interaction and collaboration with colleagues, and the sentiment expressed in employee surveys and feedback channels. Active involvement, strong working relationships, and a positive attitude towards the organization contribute to a higher engagement score.",
    },
    {
      title: "Empowerment Score",
      definition: "Measures sense of control over work and decisions.",
      icon: <FaGem size={30} />,
      details:
        "The empowerment score assesses employees' perception of autonomy and influence in their roles. Empowered employees are more motivated, proactive, and resilient. Factors promoting empowerment include trust, delegation, and participative decision-making.",
      calculation:
        "The empowerment score is determined by evaluating the level of decision-making authority, the ability to provide input and ideas, and the sense of ownership over work outcomes using data from project management tools and employee feedback channels. Clear roles and responsibilities, opportunities for innovation, and a supportive leadership style contribute to a higher empowerment score.",
    },
    {
      title: "Sentiment Score",
      definition: "Indicates overall mood and feelings about work.",
      icon: <FaSmileAlt size={30} />,
      details:
        "The sentiment score provides an overall assessment of employees' emotions and attitudes towards their work. Positive sentiment is associated with higher job satisfaction, engagement, and well-being. Factors influencing sentiment include job fit, work relationships, and organizational culture.",
      calculation:
        "The sentiment score is calculated by analyzing the tone, language, and emotions expressed in employee communications, feedback, and survey responses across various platforms. Consistently positive or neutral sentiment, expressions of satisfaction and enjoyment, and minimal negative comments contribute to a higher sentiment score.",
    },
    {
      title: "Collaboration Score",
      definition: "Evaluates teamwork and cooperation.",
      icon: <FaUsers size={30} />,
      details:
        "The collaboration score measures the effectiveness of teamwork and the ability to work together towards common goals. Strong collaboration fosters innovation, problem-solving, and a sense of shared purpose. Factors promoting collaboration include trust, open communication, and a supportive team environment.",
      calculation:
        "The collaboration score is determined by evaluating the frequency and quality of team interactions, the use of collaboration tools and platforms, and the achievement of shared objectives using data from communication and project management tools. Regular team meetings, cross-functional cooperation, and seamless information sharing contribute to a higher collaboration score.",
    },
    {
      title: "Communication Score",
      definition: "Measures clarity and effectiveness of communication.",
      icon: <FaCommentsAlt size={30} />,
      details:
        "The communication score assesses the quality and efficiency of information exchange within the organization. Effective communication ensures clarity, alignment, and timely decision-making. Factors influencing communication include transparency, active listening, and the use of appropriate channels.",
      calculation:
        "The communication score is calculated by analyzing the frequency and clarity of messages, the responsiveness to inquiries and requests, and the effectiveness of information dissemination across communication platforms. Timely, concise, and transparent communication, along with active participation in discussions and prompt follow-up, contribute to a higher communication score.",
    },
    {
      title: "Career Growth Score",
      definition: "Assesses opportunities for career advancement.",
      icon: <FaChartBar size={30} />,
      details:
        "The career growth score measures employees' perception of development opportunities and career progression within the organization. Providing growth opportunities enhances employee engagement, retention, and motivation. Factors influencing career growth include training, mentoring, and clear career paths.",
      calculation:
        "The career growth score is determined by evaluating the participation in learning and development programs, the frequency of career discussions with managers, and the internal mobility and promotion rates using data from learning management systems and performance reviews. Regular skill development, access to mentors, and transparent career advancement processes contribute to a higher career growth score.",
    },
    {
      title: "Personal Satisfaction Score",
      definition: "Measures contentment with job and work-life balance.",
      icon: <FaSmileAlt size={30} />,
      details:
        "The personal satisfaction score assesses employees' overall contentment with their job and the balance between work and personal life. High personal satisfaction leads to increased well-being, motivation, and productivity. Factors influencing personal satisfaction include job fit, work-life harmony, and a sense of accomplishment.",
      calculation:
        "The personal satisfaction score is calculated by analyzing responses to employee satisfaction surveys, the utilization of work-life balance benefits, and the sentiment expressed in work-related communications. Positive feedback on job fulfillment, healthy work-life boundaries, and a sense of personal achievement contribute to a higher personal satisfaction score.",
    },
    {
      title: "Autonomy Score",
      definition: "Evaluates freedom in how work is done.",
      icon: <FaUnlockAlt size={30} />,
      details:
        "The autonomy score measures employees' level of independence and control over their work processes. Autonomy promotes creativity, ownership, and job satisfaction. Factors influencing autonomy include trust, clear expectations, and flexible work arrangements.",
      calculation:
        "The autonomy score is determined by evaluating the level of decision-making authority, the flexibility in work schedules and locations, and the ability to choose tools and methods for task completion using data from project management and HR systems. Empowered employees, self-directed work, and a results-oriented approach contribute to a higher autonomy score.",
    },
    {
      title: "Leadership Sentiment Score",
      definition: "Indicates feelings towards leadership effectiveness.",
      icon: <FaCrown size={30} />,
      details:
        "The leadership sentiment score assesses employees' perceptions of leadership quality and effectiveness. Positive leadership sentiment is associated with trust, motivation, and organizational commitment. Factors influencing leadership sentiment include communication, integrity, and support for employee growth.",
      calculation:
        "The leadership sentiment score is calculated by analyzing the sentiment expressed in employee feedback and survey responses related to leadership, the frequency and quality of interactions with leaders, and the effectiveness of leadership communication channels. Consistent positive sentiment, regular and transparent communication from leaders, and a supportive leadership style contribute to a higher leadership sentiment score.",
    },
    {
      title: "Manager Effectiveness Score",
      definition: "Assesses the effectiveness of managers.",
      icon: <FaUserTie size={30} />,
      details:
        "The manager effectiveness score evaluates the competence and impact of managers in guiding and supporting their teams. Effective managers drive performance, engagement, and employee development. Factors influencing manager effectiveness include communication, feedback, and coaching skills.",
      calculation:
        "The manager effectiveness score is determined by evaluating the frequency and quality of manager-employee interactions, the achievement of team goals and objectives, and the sentiment expressed in manager feedback and performance reviews. Regular one-on-one meetings, constructive feedback, and a focus on employee growth and development contribute to a higher manager effectiveness score.",
    },
    {
      title: "Confidence Score",
      definition: "Measures belief in one's abilities and decisions.",
      icon: <FaThumbsUp size={30} />,
      details:
        "The confidence score assesses employees' self-assurance and belief in their capabilities. Confidence enables proactive behavior, risk-taking, and resilience. Factors influencing confidence include past successes, supportive feedback, and a growth mindset.",
      calculation:
        "The confidence score is calculated by analyzing the language and sentiment expressed in employee communications, the willingness to take on new challenges, and the level of participation in decision-making processes. Assertive communication, proactive problem-solving, and a positive self-assessment contribute to a higher confidence score.",
    },
    {
      title: "Alignment Score",
      definition: "Evaluates alignment with company goals and values.",
      icon: <FaCompass size={30} />,
      details:
        "The alignment score measures the extent to which employees understand and align with the organization's goals and values. Strong alignment fosters commitment, collaboration, and a shared sense of purpose. Factors influencing alignment include clear communication, meaningful work, and a values-driven culture.",
      calculation:
        "The alignment score is determined by evaluating the understanding and internalization of company goals and values, the alignment of individual objectives with organizational priorities, and the demonstration of values-driven behavior using data from performance management systems and employee feedback channels. Clear articulation of goals, regular reinforcement of values, and consistent behavior that aligns with company principles contribute to a higher alignment score.",
    },
  ];

  const toggleScoreType = (title) => {
    setExpandedScoreType(expandedScoreType === title ? null : title);
  };

  return (
    <Layout userDisplayName={user?.displayName} userEmail={user?.email}>
      <div className="px-4 py-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="space-y-6 p-8 bg-white rounded-3xl shadow-xl"
        >
          <h2 className="text-4xl font-bold text-center text-gray-800 mb-4">
            Definition Glossary
          </h2>
          {scoreDefinitions.map((scoreType, index) => (
            <motion.div
              key={index}
              className="bg-gray-50 rounded-lg shadow-sm overflow-hidden"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.2 }}
            >
              <motion.div
                className="flex items-center justify-between p-4 cursor-pointer"
                onClick={() => toggleScoreType(scoreType.title)}
              >
                <div className="flex items-center">
                  <div className="p-2 mr-4 bg-white rounded-full shadow">
                    {scoreType.icon}
                  </div>
                  <h3 className="text-2xl font-semibold">{scoreType.title}</h3>
                </div>
                <motion.div
                  initial={{ rotate: 0 }}
                  animate={{
                    rotate: expandedScoreType === scoreType.title ? 180 : 0,
                  }}
                  transition={{ duration: 0.2 }}
                >
                  {expandedScoreType === scoreType.title ? (
                    <IoIosArrowUp size={24} />
                  ) : (
                    <IoIosArrowDown size={24} />
                  )}
                </motion.div>
              </motion.div>
              <AnimatePresence>
                {expandedScoreType === scoreType.title && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2 }}
                    className="p-6 bg-white"
                  >
                    <div className="mb-4">
                      <h4 className="text-xl font-semibold text-gray-800 mb-2">
                        Definition
                      </h4>
                      <p className="text-gray-600">{scoreType.definition}</p>
                    </div>
                    <div className="mb-4">
                      <h4 className="text-xl font-semibold text-gray-800 mb-2">
                        Details
                      </h4>
                      <p className="text-gray-600">{scoreType.details}</p>
                    </div>
                    <div>
                      <h4 className="text-xl font-semibold text-gray-800 mb-2">
                        Calculation
                      </h4>
                      <p className="text-gray-600">{scoreType.calculation}</p>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </Layout>
  );
};

export default DefinitionGlossary;
