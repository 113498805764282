import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import routes from "./routes";
import GenericDashboard from "./routes/GenericDashboard";
import Insights from "./routes/Insights";
import Integrations from "./routes/Integrations";
import Documentation from "./routes/Documentation";
import { SignInForm } from "./routes/SignIn";
import { SignUpForm } from "./routes/SignUp";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import TermsOfService from "./routes/TermsOfService";
import AboutUs from "./routes/AboutUs";
import Product from "./routes/ProductPage";
import SimpleWrapperTemplate from "./routes/SimpleWrapperTemplate";
import Profile from "./routes/Profile";
import Onboarding from "./routes/Onboarding";
import LandingPage from "./routes/LandingPage";
import DefinitionGlossary from "./routes/DefinitionGlossary";
import OnboardingSuccessPage from "./routes/OnboardingSuccessPage"
const router = [
  {
    path: "/",
    element: (
      <SimpleWrapperTemplate
        ComponentToRender={LandingPage}
        componentProps={{}}
      />
    ),
  },
  {
    path: "/onboarding",
    element: <Onboarding />,
  },
  {
    path: "/insights",
    element: <Insights />,
  },
  {
    path: "/integrations",
    element: <Integrations />,
  },
  {
    path: "/signin",
    element: <SignInForm />,
  },
  {
    path: "/signup",
    element: <SignUpForm />,
  },
  {
    path: "/dashboard",
    element: <GenericDashboard />,
  },
  {
    path: "/documentation",
    element: <Documentation />,
  },
  {
    path: "/definition-glossary",
    element: <DefinitionGlossary />,
  },
  {
    path: "/privacy-policy",
    element: (
      <SimpleWrapperTemplate
        ComponentToRender={PrivacyPolicy}
        componentProps={{}}
      />
    ),
  },
  {
    path: "/terms-of-service",
    element: (
      <SimpleWrapperTemplate
        ComponentToRender={TermsOfService}
        componentProps={{}}
      />
    ),
  },
  {
    path: "/about-us",
    element: (
      <SimpleWrapperTemplate
        ComponentToRender={AboutUs}
        componentProps={{}}
      />
    ),
  },
  {
    path: "/product",
    element: (
      <SimpleWrapperTemplate
        ComponentToRender={Product}
        componentProps={{}}
      />
    ),
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/onboarding/complete",
    element: <OnboardingSuccessPage />
  }
];

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <RouterProvider router={createBrowserRouter(router)} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default routes;
