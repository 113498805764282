import { motion } from "framer-motion";

const SupportedIntegrations = () => {
    const integrations = [
      { name: "Slack", icon: "https://cdn-icons-png.flaticon.com/512/2111/2111615.png" },
      { name: "Teams", icon: "https://cdn-icons-png.flaticon.com/512/906/906349.png" },
      { name: "BambooHR", icon: "https://leadsbridge.com/wp-content/themes/leadsbridge/img/integration-lg-logos/logo456.png" },
      { name: "Zoom", icon: "https://cdn-icons-png.flaticon.com/512/4401/4401470.png" },
      { name: "Confluence", icon: "https://cdn-icons-png.flaticon.com/512/5968/5968793.png" },
      { name: "Google Meet", icon: "https://cdn-icons-png.flaticon.com/512/5968/5968552.png" },
      { name: "Gmail", icon: "https://cdn-icons-png.flaticon.com/512/5968/5968534.png" },
      { name: "Rippling", icon: "https://play-lh.googleusercontent.com/JmTADHoAPMDtXtseUpGpBTxHzeS8og8gxFKFjsz2xdjhMR9P-nC2cnabkOTWCjcSEyDU" },
      { name: "Workday", icon: "https://seeklogo.com/images/W/workday-logo-4971E992CF-seeklogo.com.png" },
      { name: "Jira", icon: "https://cdn-icons-png.flaticon.com/512/5968/5968875.png" },
      { name: "Github", icon: "https://cdn-icons-png.flaticon.com/512/2111/2111432.png" },
      { name: "Mattermost", icon: "https://cdn-icons-png.flaticon.com/512/906/906391.png" },
      { name: "Outlook", icon: "https://cdn-icons-png.flaticon.com/512/732/732223.png" },
      { name: "Drive", icon: "https://cdn-icons-png.flaticon.com/512/5968/5968523.png" },
    ];
  
    return (
      <section className="py-20 bg-gradient-to-b from-gray-100 to-white text-center">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold mb-12">
            Seamless Integration with Your Existing Tools
          </h2>
          <p className="text-xl mb-8">
            Worksense integrates effortlessly with the tools your team already uses.
          </p>
          <div className="flex justify-center">
            <motion.div
              className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-8"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              variants={{
                hidden: { opacity: 0, y: 50 },
                visible: {
                  opacity: 1,
                  y: 0,
                  transition: {
                    staggerChildren: 0.2,
                  },
                },
              }}
            >
              {integrations.map((integration, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.1 }}
                  className="flex flex-col items-center p-4 bg-white rounded-lg shadow-lg hover:shadow-xl transition duration-300"
                  variants={{
                    hidden: { opacity: 0, y: 50 },
                    visible: { opacity: 1, y: 0 },
                  }}
                >
                  <img src={integration.icon} alt={integration.name} className="w-16 h-16 mb-4" />
                  <span className="text-lg font-semibold text-gray-800">{integration.name}</span>
                </motion.div>
              ))}
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="flex flex-col items-center p-4 bg-white rounded-lg shadow-lg hover:shadow-xl transition duration-300"
                variants={{
                  hidden: { opacity: 0, y: 50 },
                  visible: { opacity: 1, y: 0 },
                }}
              >
                <div className="w-16 h-16 mb-4 flex items-center justify-center">
                  <div className="text-gray-600 text-2xl">+</div>
                </div>
                <span className="text-lg font-semibold text-gray-800">Many more</span>
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>
    );
  };

  export default SupportedIntegrations;