import React from "react";

const HeatMapKey = ({ viewMode }) => {
  const absoluteCategories = [
    { label: "Very Positive", color: "rgb(159, 191, 165)", range: "81-100" },
    { label: "", color: "rgb(199, 239, 207)", range: "66-80" },
    { label: "", color: "rgb(239, 245, 239)", range: "50-65" },
    { label: "", color: "rgb(255, 153, 153)", range: "41-49" },
    { label: "", color: "rgb(255, 51, 51)", range: "21-40" },
    { label: "Very Negative", color: "rgb(184, 2, 2)", range: "0-20" },
  ];

  const differentialCategories = [
    { label: "Very Positive", color: "rgb(159, 191, 165)", range: ">= +10" },
    { label: "", color: "rgb(199, 239, 207)", range: "+5 to +9" },
    { label: "", color: "rgb(239, 245, 239)", range: "-4 to +4" },
    { label: "", color: "rgb(255, 204, 102)", range: "-5 to -9" },
    { label: "Very Negative", color: "rgb(255, 102, 102)", range: "<= -10" },
  ];

  const categories = (viewMode === 'absolute' ? absoluteCategories : differentialCategories).reverse();

  return (
    <div className="flex flex-col items-start">
      <h2 className="mb-2">Heatmap Key</h2>
      <div className="flex">
        {categories.map((category, index) => (
          <div key={index} className="flex flex-col items-center">
            <div
              className="w-24 h-5 flex justify-center items-center"
              style={{ backgroundColor: category.color }}
            >
              {category.label && (
                <span className="text-xs text-black whitespace-nowrap">
                  {category.label}
                </span>
              )}
            </div>
            <span className="text-xs mt-1 text-black">{category.range}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HeatMapKey;
