export const createImageFromInitials = (size, name) => {
  if (name == null) return;

  let initials = getInitials(name);
  let color = getRandomColor();

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);

  context.fillStyle = "#A6ADBA";
  context.fillRect(0, 0, size, size);

  context.fillStyle = "#1D232A";
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.font = `${size / 2}px font-sans`;
  context.fillText(initials, size / 2, size / 2);

  return canvas.toDataURL();
};

function getInitials(name) {
  let initials;
  const nameSplit = name.split(" ");
  const nameLength = nameSplit.length;

  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else {
    return;
  }

  return initials.toUpperCase();
}

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  return color;
}
