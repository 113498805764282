import React from "react";
import Marquee from "react-fast-marquee";

import ideo from "../../images/logos/ideo.png"
import workday from "../../images/logos/workday.png"
import dave from "../../images/logos/dave.png"
import gm from "../../images/logos/gm.png"
import ibm from "../../images/logos/ibm.png"
import oracle from "../../images/logos/oracle.png"
import walmart from "../../images/logos/walmart.png"
import nba from "../../images/logos/nba.png"
import uber from "../../images/logos/uber.png"
import binance from "../../images/logos/binance.png"
import roku from "../../images/logos/roku.png"
import target from "../../images/logos/target.png"
import servicenow from "../../images/logos/servicenow.png"
import expedia from "../../images/logos/expedia.png"
import hims from "../../images/logos/hims.png";
import gallup from "../../images/logos/gallup.png";
import meta from "../../images/logos/meta.png";
import cisco from "../../images/logos/cisco.png";

const TrustedBySection = () => {
  const logos = [
    workday,
    gm,
    ibm,
    uber,
    roku,
    binance,
    expedia,
    hims,
    meta,
    oracle,
    dave,
    ideo,
    gallup,
    target,
    cisco,
    servicenow,
    walmart,
    nba
  ];

  return (
    <div className="mt-12">
      <h3 className="text-xl font-semibold text-center mb-6">
        Backed by Proven Expertise
      </h3>
      <Marquee speed={50} gradientWidth={100}>
        {logos.map((logo, index) => (
          <div key={index} className="mx-8">
            <img
              src={logo}
              alt={`Customer ${index + 1}`}
              className="h-12 grayscale hover:grayscale-0 transition duration-300 transform"
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default TrustedBySection;