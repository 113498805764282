import React from "react";
import BackedBySection from "../components/LandingPageComponents/BackedBySection";
import CTASection from "../components/LandingPageComponents/CTASection";
import EnterpriseFeatures from "../components/LandingPageComponents/EnterpriseFeatures";
import FAQSection from "../components/LandingPageComponents/FAQSection";
import ProductFeatures from "../components/LandingPageComponents/ProductFeatures";
import SupportedIntegrations from "../components/LandingPageComponents/SupportedIntegrations";
import HeroSection from "../components/LandingPageComponents/HeroSection";
import CTASectionMid from "../components/LandingPageComponents/CTASectionMid";

const LandingPage = () => (
  <div>
    <HeroSection />
    <SupportedIntegrations />
    <ProductFeatures />
    
    <EnterpriseFeatures />
    <FAQSection />
    <CTASection />
    <BackedBySection />
  </div>
);

export default LandingPage;