import React, { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  getFirestore,
  doc,
  updateDoc,
  getDoc,
  arrayUnion,
} from "firebase/firestore";
import { auth } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { getSelectedCompanyId } from "../../utils/companyStorage";
import { getApiUrl } from "../../utils/apiConfig";

const PerformerSearchBar = ({ onUpdate, performerType }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [maxHighPerformers, setMaxHighPerformers] = useState(0);
  const [highPerformersExist, setHighPerformersExist] = useState(false);
  const [currentUserUid, setCurrentUserUid] = useState("hold");

  useEffect(() => {
    onAuthStateChanged(auth, async () => {
      if (!auth.currentUser) {
        window.location.href = "/signin";
      } else {
        const userUid = await getSelectedCompanyId(auth.currentUser.uid);
        setCurrentUserUid(userUid);

        try {
          const db = getFirestore();
          const companyRef = doc(db, "companies", userUid);
          const docSnap = await getDoc(companyRef);

          if (docSnap.exists()) {
            const companyData = docSnap.data();
            const highPerformers = companyData[performerType] || [];
            setHighPerformersExist(highPerformers.length > 0);

            const idToken = await auth.currentUser.getIdToken();
            const endpointUrl = getApiUrl("get-employees");
            const url = `${endpointUrl}?company_uuid=${userUid}`;

            const response = await fetch(url, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${idToken}`,
              },
              mode: "cors",
            });
            const data = await response.json();
            setEmployees(data || []); // Ensure employees is an array
            if (performerType === "highPerformers") {
              setMaxHighPerformers(Math.ceil((data || []).length * 0.1)); // 10% of total employees
            }
          } else {
            console.error("Company document does not exist");
          }
        } catch (error) {
          console.error("Failed to fetch employees:", error);
        }
      }
    });
  }, [performerType]);

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    if (searchTerm.trim() !== "" && employees && employees.length > 0) {
      const results = employees.filter(
        (employee) =>
          !selectedEmployees.some(
            (selected) => selected.work_email === employee.work_email
          ) &&
          (employee.first_name
            .toLowerCase()
            .includes(searchTerm.toLowerCase()) ||
            employee.last_name.toLowerCase().includes(searchTerm.toLowerCase()))
      );
      setFilteredResults(results.slice(0, 10));
    } else {
      setFilteredResults([]);
    }
  };

  const handleSelectEmployee = (employee) => {
    if (
      !selectedEmployees.some((e) => e.work_email === employee.work_email) &&
      (performerType !== "highPerformers" ||
        selectedEmployees.length < maxHighPerformers)
    ) {
      setSelectedEmployees([...selectedEmployees, employee]);
      setSearchTerm("");
      setFilteredResults([]);
    }
  };

  const handleRemoveEmployee = (email) => {
    setSelectedEmployees(
      selectedEmployees.filter((e) => e.work_email !== email)
    );
  };

  const handleGenerateHighPerformerList = async () => {
    if (!currentUserUid) {
      console.log("No company selected");
      return;
    }

    const db = getFirestore();
    const companyRef = doc(db, "companies", currentUserUid);

    if (selectedEmployees.length === 0) {
      console.log("No selected employees to add");
      return;
    }

    const uuids = selectedEmployees
      .map((employee) => employee.employee_uuid)
      .filter((uuid) => uuid !== null);

    try {
      const docSnap = await getDoc(companyRef);
      if (docSnap.exists()) {
        await updateDoc(companyRef, {
          [performerType]: arrayUnion(...uuids),
        });
        setSelectedEmployees([]);
        onUpdate();
        if (performerType === "highPerformers") {
          setHighPerformersExist(true); // Hide search bar after adding high performers
        }
      } else {
        console.error("Company document does not exist");
      }
    } catch (error) {
      console.error(`Error updating ${performerType}:`, error);
    }
  };

  if (performerType === "highPerformers" && highPerformersExist) {
    return null;
  }

  return (
    <div className="max-w-xl mx-auto pt-6 px-4">
      {performerType === "highPerformers" && (
        <div
          className="mb-6 bg-red-50 border-l-4 border-red-500 text-red-700 p-4 rounded-md"
          role="alert"
        >
          <p className="font-bold">Important:</p>
          <p>
            You can only add high performers once and are limited to 10% of
            total employees.
          </p>
        </div>
      )}
      <form
        onSubmit={(e) => e.preventDefault()}
        className="flex items-center justify-center mb-4"
      >
        <div className="relative w-full mr-4">
          <input
            type="search"
            id="default-search"
            className="block w-full px-4 py-3 text-gray-900 border border-gray-300 rounded-md bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search Employees..."
            onChange={handleSearchChange}
            value={searchTerm}
          />
        </div>
        <button
          className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          onClick={handleGenerateHighPerformerList}
        >
          Add
        </button>
      </form>
      {employees.length === 0 ? (
        <p className="text-red-600">No employees found for this company.</p>
      ) : filteredResults.length > 0 && searchTerm ? (
        <div className="absolute z-10 w-full mt-2 bg-white shadow-lg rounded-md border border-gray-200">
          {filteredResults.map((result, index) => (
            <div
              key={index}
              className="px-4 py-3 cursor-pointer hover:bg-gray-100"
              onClick={() => handleSelectEmployee(result)}
            >
              {result.first_name} {result.last_name} (
              {result.job_title || "N/A"})
            </div>
          ))}
        </div>
      ) : null}
      {selectedEmployees.length > 0 && (
        <div className="mt-8">
          <h3 className="text-lg font-semibold mb-2">Selected Employees:</h3>
          <ul className="space-y-4">
            {selectedEmployees.map((employee) => (
              <li
                key={employee.work_email}
                className="flex items-center justify-between bg-gray-100 px-4 py-3 rounded-md"
              >
                <span className="font-medium">
                  {employee.first_name} {employee.last_name} (
                  {employee.work_email})
                </span>
                <button
                  onClick={() => handleRemoveEmployee(employee.work_email)}
                  className="text-red-500 hover:text-red-700 focus:outline-none"
                >
                  <AiOutlineClose size={20} />
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default PerformerSearchBar;
