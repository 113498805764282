import React, { useState } from "react";
import DistributionBar from "./DistributionBar";
import ScoreBar from "./ScoreBar";
import Loader from "../utils/Loader";
import { BsChevronDown, BsChevronUp, BsInfoCircle } from "react-icons/bs";
import { mapScoreTypes } from "../utils/MapFunctions";
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const scoreDefinitions = {
  burnoutScore: "Measures the level of burnout, indicating stress and fatigue.",
  energyScore: "Assesses overall energy and vitality.",
  psychologicalSafetyScore: "Measures team safety to take risks and speak up.",
  belongingScore: "Measures the sense of inclusion and belonging.",
  positivityScore: "Indicates overall positive outlook and attitude.",
  physicalEnvironmentScore: "Assesses the quality of the physical workspace.",
  productivityScore: "Measures effectiveness and output of work.",
  motivationScore: "Evaluates drive and willingness to work.",
  focusScore: "Assesses ability to concentrate on tasks.",
  recognitionScore: "Measures how valued and appreciated employees feel.",
  engagementScore: "Assesses involvement and enthusiasm at work.",
  empowermentScore: "Measures sense of control over work and decisions.",
  sentimentScore: "Indicates overall mood and feelings about work.",
  collaborationScore: "Evaluates teamwork and cooperation.",
  communicationScore: "Measures clarity and effectiveness of communication.",
  careerGrowthScore: "Assesses opportunities for career advancement.",
  personalSatisfactionScore: "Measures contentment with job and work-life balance.",
  autonomyScore: "Evaluates freedom in how work is done.",
  leadershipSentimentScore: "Indicates feelings towards leadership effectiveness.",
  managerEffectivenessScore: "Assesses the effectiveness of managers.",
  confidenceScore: "Measures belief in one's abilities and decisions.",
  alignmentScore: "Evaluates alignment with company goals and values.",
};

const scoreDimensions = {
  Wellbeing: {
    description: "Overall measure of employee wellbeing.",
    scores: [
      "burnoutScore",
      "energyScore",
      "psychologicalSafetyScore",
      "belongingScore",
      "positivityScore",
      "physicalEnvironmentScore",
    ],
  },
  Performance: {
    description: "Overall measure of employee performance.",
    scores: [
      "productivityScore",
      "motivationScore",
      "focusScore",
      "recognitionScore",
    ],
  },
  Engagement: {
    description: "Overall measure of employee engagement.",
    scores: [
      "engagementScore",
      "empowermentScore",
      "energyScore",
      "sentimentScore",
      "collaborationScore",
      "communicationScore",
    ],
  },
  Growth: {
    description: "Overall measure of employee growth.",
    scores: [
      "careerGrowthScore",
      "personalSatisfactionScore",
      "recognitionScore",
      "autonomyScore",
    ],
  },
  "Leadership and Vision": {
    description: "Overall measure of leadership and vision.",
    scores: [
      "leadershipSentimentScore",
      "managerEffectivenessScore",
      "confidenceScore",
      "alignmentScore",
    ],
  },
};

const ScoreTable = ({ items }) => {
  const [expandedRows, setExpandedRows] = useState({});

  if (!items) {
    return <Loader loading={true} size={30} color={"#123abc"} loadingText={"Loading Data"} />;
  }

  const toggleRow = (dimension) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [dimension]: !prevState[dimension],
    }));
  };

  const dimensionScores = Object.entries(scoreDimensions).map(
    ([dimension, { description, scores }]) => {
      const relevantScores = items.filter((item) =>
        scores.includes(item.score_type)
      );
      const averageScore =
        relevantScores.reduce((acc, curr) => {
          if (curr.score_type === "burnoutScore" && curr.score !== null) {
            return acc + (100 - curr.score);
          }
          return curr.score !== null ? acc + curr.score : acc;
        }, 0) /
        (relevantScores.filter((item) => item.score !== null).length || 1);

      const aggregatedDistribution = relevantScores.reduce(
        (acc, curr) => {
          const distribution = curr.distribution;
          if (curr.score_type === "burnoutScore") {
            return {
              very_negative:
                acc.very_negative + (distribution.very_positive || 0),
              negative: acc.negative + (distribution.positive || 0),
              neutral: acc.neutral + (distribution.neutral || 0),
              positive: acc.positive + (distribution.negative || 0),
              very_positive:
                acc.very_positive + (distribution.very_negative || 0),
            };
          } else {
            Object.keys(acc).forEach((key) => {
              acc[key] += distribution[key] || 0;
            });
            return acc;
          }
        },
        {
          very_negative: 0,
          negative: 0,
          neutral: 0,
          positive: 0,
          very_positive: 0,
        }
      );

      const formattedAverageScore = isNaN(averageScore)
        ? "N/A"
        : Math.round(averageScore);

      return {
        dimension,
        description,
        averageScore: formattedAverageScore,
        scores: relevantScores,
        aggregatedDistribution,
      };
    }
  );

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" className="px-6 py-3 w-1/4">
              Dimension
            </th>
            <th scope="col" className="px-6 py-3 w-2/4">
              Score
            </th>
            <th scope="col" className="px-6 py-3 w-1/4">
              Distribution
            </th>
          </tr>
        </thead>
        <tbody>
          {dimensionScores.flatMap(
            ({ dimension, description, averageScore, scores, aggregatedDistribution }) => [
              <tr
                key={dimension}
                onClick={() => toggleRow(dimension)}
                className="cursor-pointer bg-white border-b dark:bg-gray-800 dark:border-gray-700"
              >
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex items-center">
                  {expandedRows[dimension] ? (
                    <BsChevronUp className="mr-2" />
                  ) : (
                    <BsChevronDown className="mr-2" />
                  )}
                  <Tooltip
                    title={description}
                    position="top"
                    trigger="mouseenter"
                  >
                    <span>{dimension}</span>
                  </Tooltip>
                  <a
                    href="/definition-glossary"
                    className="ml-2 text-blue-500 hover:underline flex items-center"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: 'pointer' }}
                  >
                    <BsInfoCircle className="ml-1 text-lg hover:text-blue-700" />
                  </a>
                </td>
                <td className="px-6 py-4">
                  <div className="flex items-center justify-center w-full">
                    <ScoreBar
                      score={averageScore !== "N/A" ? averageScore : 0}
                    />
                  </div>
                </td>
                <td className="px-6 py-4">
                  <DistributionBar
                    veryNegative={aggregatedDistribution.very_negative}
                    negative={aggregatedDistribution.negative}
                    neutral={aggregatedDistribution.neutral}
                    positive={aggregatedDistribution.positive}
                    veryPositive={aggregatedDistribution.very_positive}
                  />
                </td>
              </tr>,
              expandedRows[dimension] &&
                scores.map((score, index) => (
                  <tr
                    key={`${dimension}-${index}`}
                    className="bg-gray-50 dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-700 transition-transform duration-150 transform hover:scale-[1.02]"
                  >
                    <td className="px-6 py-4 pl-12">
                      <Tooltip
                        title={scoreDefinitions[score.score_type]}
                        position="top"
                        trigger="mouseenter"
                      >
                        <span className="cursor-pointer">{mapScoreTypes(score.score_type)}</span>
                      </Tooltip>
                    </td>
                    <td className="px-6 py-4">
                      <div
                        className="flex items-center justify-center w-full"
                        style={{ opacity: 0.8 }}
                      >
                        <ScoreBar
                          score={
                            score.score !== null ? Math.round(score.score) : 0
                          }
                          scoreType={score.score_type}
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4" style={{ opacity: 0.8 }}>
                      <DistributionBar
                        veryNegative={score.distribution.very_negative}
                        negative={score.distribution.negative}
                        neutral={score.distribution.neutral}
                        positive={score.distribution.positive}
                        veryPositive={score.distribution.very_positive}
                        isBurnout={score.score_type === "burnoutScore"}
                      />
                    </td>
                  </tr>
                )),
            ]
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ScoreTable;
