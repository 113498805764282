import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import * as Constants from "../utils/Constants";

const validatePayment = async (userId) => {
  const userDocRef = doc(db, Constants.COMPANY_TABLE_NAME, userId);
  const userDocSnapshot = await getDoc(userDocRef);
  if (userDocSnapshot.exists()) {
    const userData = userDocSnapshot.data();
    return userData.isPaid; 
  }
  return false;
};

export default validatePayment;
