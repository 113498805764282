import React from "react";

const ScoreBar = ({ score, scoreType }) => {
  const widthPercentage = `${(score / 100) * 100}%`;

  const getBarColor = () => {
    // Reverse color logic specifically for 'burnoutScore'
    if (scoreType === 'burnoutScore') {
      return score <= 50 ? '#00CCCC' : '#FA8072'; // Green for low, red for high
    } else {
      return score > 50 ? '#00CCCC' : '#FA8072'; // Green for high, red for low
    }
  };

  return (
    <div className="w-full flex items-center">
      <span className="font-medium text-gray-900 dark:text-white mr-2">
        {score}
      </span>
      <div className="w-full bg-gray-200 rounded-full h-4 overflow-hidden">
        <div
          style={{ width: widthPercentage, backgroundColor: getBarColor(score) }}
          className="h-full"
        ></div>
      </div>
    </div>
  );
};

export default ScoreBar;
