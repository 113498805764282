export const getTopAndBottomTeams = (datasets, labels) => {
  // Calculate the overall average and filter the top 1 department
  const topDepartments = Object.values(datasets)
    // Ensure that we return enough data to display on the chart (at least 60%)
    .filter(
      (dept) =>
        labels.length * 0.6 >= dept.data.filter((item) => item === null).length
    )
    .map((dept) => ({
      ...dept,
      overallAverage: dept["overallAverage"].length
        ? dept["overallAverage"].reduce((acc, cur) => acc + cur, 0) /
          dept["overallAverage"].length
        : 0,
    }))
    .sort((a, b) => b["overallAverage"] - a["overallAverage"]) // Sort by overall average descending
    .slice(0, 1); // Take top 1

  // Calculate the overall average and filter the bottom 1 department
  const bottomDepartments = Object.values(datasets)
    // Ensure that we return enough data to display on the chart (at least 60%)
    .filter(
      (dept) =>
        labels.length * 0.6 >= dept.data.filter((item) => item === null).length
    )
    .map((dept) => ({
      ...dept,
      overallAverage: dept["overallAverage"].length
        ? dept["overallAverage"].reduce((acc, cur) => acc + cur, 0) /
          dept["overallAverage"].length
        : 50,
    }))
    .sort((a, b) => a["overallAverage"] - b["overallAverage"]) // Sort by overall average descending
    .slice(0, 1); // Take bottom 1

  return topDepartments.concat(bottomDepartments);
};

export const formatDepartments = (departments) => {
  // Calculate averages and store them with the department info
  const departmentsWithAverages = departments.map((dept) => ({
    ...dept,
    average: calculateAverage(dept.data),
  }));

  // Determine the range of averages
  const minAverage = Math.min(
    ...departmentsWithAverages.map((dept) => dept.average)
  );
  const maxAverage = Math.max(
    ...departmentsWithAverages.map((dept) => dept.average)
  );

  // Assign the calculated color based on the average
  const departmentsFormatted = departmentsWithAverages.map((dept) => ({
    label: dept.label,
    data: dept.data,
    borderColor: getColor(dept.average, minAverage, maxAverage),
  }));

  // Now departmentsFormatted has each department with a borderColor assigned based on their average.
  return departmentsFormatted;
};

export const getCompanyAverage = (overallScores, countScores) => {
  // Calculate overall average scores
  const overallAverageScores = overallScores.map((total, index) => {
    return countScores[index] > 0 ? total / countScores[index] : null;
  });

  return {
    label: "Overall Average",
    data: overallAverageScores,
    borderColor: "rgb(211, 211, 211)", // Distinct color for overall average
  };
};

export const getRandomColor = () => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r}, ${g}, ${b})`;
};

// Function to generate color based on average
function getColor(average, minAverage, maxAverage) {
  const midPoint = (maxAverage + minAverage) / 2;
  if (average >= midPoint) {
    // Scale of green from mid to max
    const greenIntensity =
      (230 * (average - midPoint)) / (maxAverage - midPoint);
    return `rgb(0, ${Math.round(greenIntensity)}, 0)`;
  } else {
    // Scale of red from min to mid
    const redIntensity = (230 * (midPoint - average)) / (midPoint - minAverage);
    return `rgb(${Math.round(redIntensity)}, 0, 0)`;
  }
}

// Function to calculate the average of an array
function calculateAverage(array) {
  const sum = array.reduce((acc, val) => acc + val, 0);
  return sum / array.length;
}
