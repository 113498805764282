import React, { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { saveBambooHrData } from "../../utils/IntegrationHelper"; // Replace with the actual path

const BambooHrInit = () => {
  const [subdomain, setSubdomain] = useState("");
  const [apiKey, setApiKey] = useState("");

  const handleSubdomainChange = (event) => {
    setSubdomain(event.target.value);
  };

  const handleApiKeyChange = (event) => {
    setApiKey(event.target.value);
  };

  const handleSaveClick = async () => {
    if (subdomain.trim() === "" || apiKey.trim() === "") {
      // Show an alert or perform any action for empty values
      alert("Subdomain and API Key are required.");
      return;
    }

    // Call the function from the integration helper class and pass subdomain and apiKey
    await saveBambooHrData(subdomain, apiKey);
    window.location.href = "/integrations";
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col">
        <label
          htmlFor="subdomain"
          className="text-sm font-medium text-gray-700 flex items-center"
        >
          Subdomain
          <FaQuestionCircle
            className="ml-2 text-lg text-gray-500"
            title="If you access BambooHR at https://mycompany.bamboohr.com, then the subdomain is 'mycompany'."
          />
        </label>
        <input
          type="text"
          value={subdomain}
          onChange={handleSubdomainChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter subdomain"
        />
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="apiKey"
          className="text-sm font-medium text-gray-700 flex items-center"
        >
          API Key
          <FaQuestionCircle
            className="ml-2 text-lg text-gray-500"
            title="Get this by clicking your profile in the upper right -> API Keys -> Add New Key."
          />
        </label>
        <input
          type="text"
          value={apiKey}
          onChange={handleApiKeyChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter API Key"
        />
      </div>

      <button
        onClick={handleSaveClick}
        className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Save
      </button>
    </div>
  );
};

export default BambooHrInit;
