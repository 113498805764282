import React from 'react';
import { AiOutlineMenu } from "react-icons/ai";
import worksenselogo from "../../images/logos/worksenselogo3.png";

const NavBar = () => {
  return (
    <nav className="bg-white dark:bg-gray-900 fixed w-full z-30 top-0 left-0 shadow-md">
      <div className="max-w-screen-xl mx-auto flex items-center justify-between p-4">
        <a href="/" className="flex items-center">
          <img src={worksenselogo} className="h-10" alt="Worksense Logo" />
        </a>
        <div className="flex space-x-3">
          <a
            href="https://cal.com/arshanahmad/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="text-[#14576E] hover:text-white bg-white hover:bg-[#14576E] border-2 border-[#14576E] font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-300"
          >
            Schedule Demo
          </a>
          <a
            href="/signin"
            className="text-white bg-[#176B87] hover:bg-[#14576E] font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-300"
          >
            Sign In
          </a>
          {/* Uncomment below if you want to include a responsive menu button */}
          {/* <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <AiOutlineMenu size="20px"/>
            <span className="sr-only">Open main menu</span>
          </button> */}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
