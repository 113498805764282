import React, { useState } from "react";
import { saveMattermostData } from "../../utils/IntegrationHelper"; // Replace with the actual path
import { FaQuestionCircle } from "react-icons/fa";

const MattermostInit = (userIdToken) => {
  const [subdomain, setSubdomain] = useState("");
  const [user, setUser] = useState("");
  const [pass, setPass] = useState("");

  const handleSubdomainChange = (event) => {
    setSubdomain(event.target.value);
  };

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };
  const handlePassChange = (event) => {
    setPass(event.target.value);
  };

  const handleSaveClick = async () => {
    if (subdomain.trim() === "" || user.trim() === "" || pass.trim() === "") {
      // Show an alert or perform any action for empty values
      alert("Subdomain, user, and password are required.");
      return;
    }
    // Call the function from the integration helper class and pass subdomain, user, and pass
    await saveMattermostData(subdomain, user, pass);
    window.location.href = "/integrations";
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col">
        <label
          htmlFor="subdomain"
          className="text-sm font-medium text-gray-700 flex items-center"
        >
          Subdomain
          <FaQuestionCircle
            className="ml-2 text-lg text-gray-500"
            title="This should be the base URL of your Mattermost workspace. Do not include trailing slashes"
          />
        </label>
        <input
          type="text"
          value={subdomain}
          onChange={handleSubdomainChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter Subdomain"
        />
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="user"
          className="text-sm font-medium text-gray-700 flex items-center"
        >
          Username
          <FaQuestionCircle
            className="ml-2 text-lg text-gray-500"
            title="This should be the username of the system admin account"
          />
        </label>
        <input
          type="text"
          value={user}
          onChange={handleUserChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter User"
        />
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="pass"
          className="text-sm font-medium text-gray-700 flex items-center"
        >
          Password
          <FaQuestionCircle
            className="ml-2 text-lg text-gray-500"
            title="This should be the password of the system admin account."
          />
        </label>
        <input
          type="text"
          value={pass}
          onChange={handlePassChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter Password"
        />
      </div>

      <button
        onClick={handleSaveClick}
        className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Save
      </button>
    </div>
  );
};

export default MattermostInit;
