import React, { useMemo, useState, useEffect } from "react";
import { mapScoreTypes } from "../utils/MapFunctions";
import { useAdminStatus } from "../utils/useAdminStatus";

const HeatMap = ({
  data,
  searchTerm,
  currentPage,
  setCurrentPage,
  viewMode,
}) => {
  const isAdminUser = useAdminStatus();
  const [hoveredCell, setHoveredCell] = useState({ row: null, col: null });
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const maxWidth = Math.max(960, viewportWidth * 0.9);
  const baseCellSize = 50;
  const baseFontSize = 14;
  const cellPadding = 2;
  const initialColHeaderSpace = 120;
  let initialRowHeaderWidth = 100;
  const colHeaderPaddingX = 10;
  const colHeaderPaddingY = 20;
  const itemsPerPage = 10;

  const [cellSize, setCellSize] = useState(baseCellSize);
  const [fontSize, setFontSize] = useState(baseFontSize);

  const validData = useMemo(() => {
    const filteredData = data?.filter((dept) => dept.department != null) ?? [];
    return filteredData
      .filter((dept) =>
        dept.department.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a, b) => a.department.localeCompare(b.department));
  }, [data, searchTerm]);

  const cols = useMemo(() => {
    const baseCols = [
      "number_of_employees",
      "total_number_of_messages",
      ...Object.keys(validData[0]?.scores ?? {}),
    ];
    return isAdminUser
      ? baseCols
      : baseCols.filter(
          (col) =>
            col !== "number_of_employees" && col !== "total_number_of_messages"
        );
  }, [validData, isAdminUser]);

  const companyAverages = useMemo(() => {
    return cols.reduce((acc, metric) => {
      if (
        metric === "number_of_employees" ||
        metric === "total_number_of_messages"
      ) {
        const sum = data.reduce((sum, dept) => sum + (dept[metric] || 0), 0);
        acc[metric] = sum;
      } else {
        const sum = data.reduce(
          (sum, dept) => sum + (dept.scores[metric] || 0),
          0
        );
        const avg = sum / data.length;
        acc[metric] = avg;
      }
      return acc;
    }, {});
  }, [cols, data]);

  useEffect(() => {
    const availableWidth = maxWidth - initialRowHeaderWidth;
    const maxCellWidth = availableWidth / cols.length - cellPadding;
    const newSize = Math.min(maxCellWidth, baseCellSize);
    setCellSize(newSize);
    const newFontSize = (baseFontSize * newSize) / baseCellSize;
    setFontSize(Math.max(newFontSize, 10));
  }, [cols.length, maxWidth]);

  const { rowHeaderWidth, colHeaderSpace, svgWidth, svgHeight } =
    useMemo(() => {
      const calculatedRowHeaderWidth = Math.max(
        initialRowHeaderWidth,
        cols.reduce((acc, col) => Math.max(acc, col.length * fontSize * 0.6), 0)
      );
      const calculatedColHeaderSpace = initialColHeaderSpace;
      const extraWidthForDiagonalHeaders =
        (Math.max(...cols.map((col) => mapScoreTypes(col).length)) *
          (fontSize / 2)) /
          Math.cos((-45 * Math.PI) / 180) -
        cellSize;
      const calculatedSvgWidth =
        calculatedRowHeaderWidth +
        (cellSize + cellPadding) * cols.length +
        extraWidthForDiagonalHeaders;
      const calculatedSvgHeight =
        calculatedColHeaderSpace +
        (cellSize + cellPadding) *
          Math.min(itemsPerPage + 1, validData.length + 1);

      return {
        rowHeaderWidth: calculatedRowHeaderWidth,
        colHeaderSpace: calculatedColHeaderSpace,
        svgWidth: calculatedSvgWidth,
        svgHeight: calculatedSvgHeight,
      };
    }, [cellSize, cols, fontSize, itemsPerPage, validData.length]);

  const getColorForValue = (value, metric, isCompanyAverage = false) => {
    if (
      metric === "number_of_employees" ||
      metric === "total_number_of_messages"
    ) {
      return isCompanyAverage ? "rgb(255, 229, 180)" : "rgb(255, 245, 238)";
    }
    if (viewMode === "absolute") {
      if (value == 0) {
        return "rgb(239, 245, 239)";
      }

      const isBurnout = metric === "burnout_score";
      const darkGreenStart = 81;
      const lightGreenEnd = 80;
      const lightGreenStart = 66;
      const grayEnd = 65;
      const grayStart = 50;
      const lightRedEnd = 49;
      const lightRedStart = 41;
      const mediumRedEnd = 40;
      const mediumRedStart = 21;
      const darkRedEnd = 20;

      if (isBurnout) {
        if (value <= darkRedEnd) {
          return `rgb(159, 191, 165)`;
        } else if (value <= mediumRedEnd) {
          return `rgb(199, 239, 207)`;
        } else if (value <= lightRedEnd) {
          return `rgb(255, 153, 153)`;
        } else if (value <= lightGreenEnd) {
          return `rgb(255, 51, 51)`;
        } else {
          return `rgb(184, 2, 2)`;
        }
      } else {
        if (value >= darkGreenStart) {
          return `rgb(159, 191, 165)`;
        } else if (value >= lightGreenStart) {
          return `rgb(199, 239, 207)`;
        } else if (value >= grayStart) {
          return "rgb(239, 245, 239)";
        } else if (value >= lightRedStart) {
          return `rgb(255, 153, 153)`;
        } else if (value >= mediumRedStart) {
          return `rgb(255, 51, 51)`;
        } else {
          return `rgb(184, 2, 2)`;
        }
      }
    } else {
      const differential = value - companyAverages[metric];
      if (value === 0) {
        return "rgb(239, 245, 239)";
      } else if (metric === "burnout_score") {
        if (differential <= -10) {
          return `rgb(159, 191, 165)`;
        } else if (differential <= -5) {
          return `rgb(199, 239, 207)`;
        } else if (differential < 0) {
          return "rgb(239, 245, 239)";
        } else if (differential < 5) {
          return "rgb(255, 204, 102)";
        } else if (differential < 10) {
          return "rgb(255, 102, 102)";
        } else {
          return "rgb(255, 0, 0)";
        }
      } else {
        if (differential >= 10) {
          return `rgb(159, 191, 165)`;
        } else if (differential >= 5) {
          return `rgb(199, 239, 207)`;
        } else if (differential >= 0) {
          return "rgb(239, 245, 239)";
        } else if (differential >= -5) {
          return "rgb(255, 204, 102)";
        } else if (differential >= -10) {
          return "rgb(255, 102, 102)";
        } else {
          return "rgb(255, 0, 0)";
        }
      }
    }
  };

  const getLabelForValue = (value, metric) => {
    if (
      metric === "number_of_employees" ||
      metric === "total_number_of_messages"
    ) {
      return value.toString();
    }
    if (viewMode === "absolute") {
      return value.toFixed(0);
    } else {
      const differential = value - companyAverages[metric];
      return differential >= 0
        ? `+${differential.toFixed(1)}`
        : differential.toFixed(1);
    }
  };

  const handleMouseOver = (row, col) => {
    setHoveredCell({ row, col });
  };

  const handleMouseOut = () => {
    setHoveredCell({ row: null, col: null });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPagination = () => {
    const pageCount = Math.ceil(validData.length / itemsPerPage);
    const pages = [];

    for (let i = 0; i < pageCount; i++) {
      pages.push(
        <li key={i} className="inline-block">
          <button
            onClick={() => handlePageChange(i)}
            className={`mx-1 px-3 py-2 rounded-lg text-sm font-medium leading-5 transition-colors duration-150 ${
              i === currentPage
                ? "bg-blue-600 text-white"
                : "bg-white border border-gray-300 text-gray-700 hover:bg-gray-100"
            } focus:shadow-outline-blue focus:outline-none`}
          >
            {i + 1}
          </button>
        </li>
      );
    }

    return (
      <nav className="flex justify-center mt-4">
        <ul className="inline-flex -space-x-px">{pages}</ul>
      </nav>
    );
  };

  const visibleDepartments = [
    ...validData.slice(
      currentPage * itemsPerPage,
      (currentPage + 1) * itemsPerPage
    ),
  ];

  return (
    <div className="mx-auto mt-0" style={{ maxWidth: maxWidth }}>
      <div className="flex justify-center">
        <svg width={svgWidth} height={svgHeight}>
          {/* Column Headers */}
          {cols.map((col, index) => (
            <text
              key={col}
              x={
                rowHeaderWidth + index * (cellSize + cellPadding) + cellSize / 2
              }
              y={colHeaderSpace - colHeaderPaddingY}
              transform={`rotate(-45 ${
                rowHeaderWidth + index * (cellSize + cellPadding) + cellSize / 2
              },${colHeaderSpace - colHeaderPaddingX})`}
              fontSize={fontSize}
              textAnchor="start"
              dominantBaseline="hanging"
              style={{
                fill: hoveredCell.col === index ? "black" : "grey",
                fontWeight: hoveredCell.col === index ? "bold" : "normal",
              }}
            >
              {col === "number_of_employees"
                ? "# Employees"
                : col === "total_number_of_messages"
                ? "# Messages"
                : mapScoreTypes(col)}
            </text>
          ))}
          {/* Company Average Row */}
          <g>
            <rect
              x={rowHeaderWidth}
              y={colHeaderSpace}
              width={(cellSize + cellPadding) * cols.length}
              height={cellSize}
              fill="white"
              stroke="black"
              strokeWidth={0}
              rx={0}
              ry={0}
              style={{ filter: "drop-shadow(0px 0px 0px rgba(0,0,0,0.3))" }}
            />
            {cols.map((metric, colIndex) => {
              const value =
                metric === "number_of_employees" ||
                metric === "total_number_of_messages"
                  ? companyAverages[metric]
                  : viewMode === "absolute"
                  ? Math.round(companyAverages[metric] || 0)
                  : 0;
              const isHighlightedCell =
                hoveredCell.row === -1 && hoveredCell.col === colIndex;
              const color = getColorForValue(value, metric, true);
              const label = getLabelForValue(value, metric);
              return (
                <g
                  key={`company-average-${metric}`}
                  onMouseOver={() => handleMouseOver(-1, colIndex)}
                  onMouseOut={handleMouseOut}
                >
                  {/* Row Header */}
                  {colIndex === 0 && (
                    <text
                      x={5}
                      y={colHeaderSpace + cellSize / 2}
                      fontSize={fontSize}
                      textAnchor="start"
                      alignmentBaseline="central"
                      style={{
                        fill: "black",
                        fontWeight: "bold",
                      }}
                    >
                      {"Company Average"}
                    </text>
                  )}
                  {/* Heatmap Cell */}
                  <rect
                    x={rowHeaderWidth + colIndex * (cellSize + cellPadding)}
                    y={colHeaderSpace}
                    width={cellSize}
                    height={cellSize}
                    fill={color}
                    strokeWidth={cellPadding}
                  />
                  {/* Label Text */}
                  <text
                    x={
                      rowHeaderWidth +
                      colIndex * (cellSize + cellPadding) +
                      cellSize / 2
                    }
                    y={colHeaderSpace + cellSize / 2}
                    fontSize={fontSize - 2}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    style={{
                      fill: "black",
                      fontWeight: "bold",
                    }}
                  >
                    {label}
                  </text>
                </g>
              );
            })}
          </g>
          {/* Department Rows */}
          {visibleDepartments.map((dept, rowIndex) =>
            cols.map((metric, colIndex) => {
              const value =
                metric === "number_of_employees" ||
                metric === "total_number_of_messages"
                  ? dept[metric] || 0
                  : dept.scores[metric]
                  ? dept.scores[metric]
                  : 0;
              const isHighlightedCell =
                rowIndex === hoveredCell.row && colIndex === hoveredCell.col;
              const color = getColorForValue(value, metric);
              const label = getLabelForValue(value, metric);
              return (
                <g
                  key={`${dept.department}-${metric}`}
                  onMouseOver={() => handleMouseOver(rowIndex, colIndex)}
                  onMouseOut={handleMouseOut}
                >
                  {/* Row Headers */}
                  {colIndex === 0 && (
                    <text
                      x={5}
                      y={
                        colHeaderSpace +
                        (rowIndex + 1) * (cellSize + cellPadding) +
                        cellSize / 2
                      }
                      fontSize={fontSize}
                      textAnchor="start"
                      alignmentBaseline="central"
                      style={{
                        fill: rowIndex === hoveredCell.row ? "black" : "grey",
                        fontWeight:
                          rowIndex === hoveredCell.row ? "bold" : "normal",
                      }}
                    >
                      {dept.department}
                    </text>
                  )}
                  {/* Heatmap Cells */}
                  <rect
                    x={rowHeaderWidth + colIndex * (cellSize + cellPadding)}
                    y={
                      colHeaderSpace + (rowIndex + 1) * (cellSize + cellPadding)
                    }
                    width={cellSize}
                    height={cellSize}
                    fill={color}
                    stroke={isHighlightedCell ? "black" : "#fff"}
                    strokeWidth={cellPadding}
                  />
                  {/* Label Text */}
                  <text
                    x={
                      rowHeaderWidth +
                      colIndex * (cellSize + cellPadding) +
                      cellSize / 2
                    }
                    y={
                      colHeaderSpace +
                      (rowIndex + 1) * (cellSize + cellPadding) +
                      cellSize / 2
                    }
                    fontSize={fontSize - 2}
                    textAnchor="middle"
                    alignmentBaseline="central"
                    style={{
                      fill: "black",
                    }}
                  >
                    {label}
                  </text>
                </g>
              );
            })
          )}
        </svg>
      </div>
      {renderPagination()}
    </div>
  );
};

export default HeatMap;