import React from "react";
import Footer from "../components/LandingPageComponents/Footer";
import NavBar from "../components/LandingPageComponents/NavBar";

export default function SimpleWrapperTemplate({ ComponentToRender, componentProps }) {
  return (
    <div className="overflow-x-hidden">
      <header>
        <NavBar />
      </header>
      <main className="mt-16 sm:mt-20">
        <ComponentToRender {...componentProps} />
      </main>
      <Footer />
    </div>
  );
}