import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="bg-white text-black pt-16">
      <div className="max-w-screen-xl mx-auto flex items-center px-4 py-8">
        <div className="prose prose-lg max-w-full">
          <h1 className="text-3xl font-semibold text-[#14576E] text-center">
            PRIVACY POLICY
          </h1>
          <p>Policy last updated 6 February 2024</p>

          <p>
            Worksense AI (<b>“Worksense,”</b> <b>“we,”</b> or <b>“us”</b>) wants
            you to be familiar with how we collect, use, disclose, and protect
            information. This Privacy Policy describes our practices in
            connection with information that we collect through website(s)
            operated by us from which you are accessing this Privacy Policy,
            including https://worksenseai.com/, and HTML-formatted email
            messages that we send to you that link to this Privacy Policy
            (collectively, the <b>“Services”</b>).
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6 ">
            PERSONAL INFORMATION
          </h2>

          <p>
            <b>“Personal Information”</b> is information that identifies you as
            an individual or relates to an identifiable individual. The Services
            collect Personal Information, including:
          </p>
          <ul>
            <li>Name</li>
            <li>Telephone Number</li>
            <li>Email and postal address</li>
            <li>
              Business contact information, such as your current company and
              your position at the company
            </li>
            <li>
              IP address (we may also derive your approximate location from your
              IP address)
            </li>
            <li>
              Username/password when you choose to create an account with us
            </li>
          </ul>

          <h3 className="text-xl text-[#14576E] mt-5">
            Use of Personal Information
          </h3>
          <p>
            We and our service providers may use Personal Information for the
            following purposes:
          </p>
          <ul>
            <li>
              <b class="text-[#14576E]">
                Providing the Services’ functionality
              </b>
              <ul>
                <li>
                  To provide the Services’ functionality to you, such as
                  arranging access to your account.
                </li>
                <li>
                  To respond to your inquiries and fulfill your requests, such
                  as when you contact us via one of our online contact forms or
                  otherwise, or request information about our services.
                </li>
                <li>
                  To send administrative information to you, such as changes to
                  our terms, conditions, and policies.
                </li>
              </ul>
            </li>
            <li>
              <b class="text-[#14576E]">
                Providing you with our newsletter and/or other marketing
                materials
              </b>
              <ul>
                <li>
                  To send you marketing related emails, with information about
                  our services, new products and other news about our
                  organization.
                </li>
              </ul>
            </li>
            <li>
              <b class="text-[#14576E]">
                Analyzing Personal Information for business reporting and
                providing personalized services
              </b>
              <ul>
                <li>
                  To analyze or predict our users’ preferences in order to
                  prepare aggregated trend reports on how our digital content is
                  used, so we can improve our Services.
                </li>
                <li>
                  To better understand your interests and preferences, so that
                  we can personalize our interactions with you and provide you
                  with information and/or offers tailored to your interests.
                </li>
              </ul>
            </li>
            <li>
              <b class="text-[#14576E]">
                Aggregating and/or anonymizing Personal Information
              </b>
              <ul>
                <li>
                  We may aggregate and/or anonymize Personal Information so that
                  it will no longer be considered Personal Information. We do so
                  to generate other data for our use, which we may use and
                  disclose for any purpose, as it no longer identifies you or
                  any other individual.
                </li>
              </ul>
            </li>
            <li>
              <b class="text-[#14576E]">Accomplishing our business purposes.</b>
              <ul>
                <li>
                  For audits, to verify that our internal processes function as
                  intended and to address legal, regulatory, or contractual
                  requirements;
                </li>
                <li>
                  For fraud prevention and security monitoring purposes,
                  including to detect and prevent cyberattacks or attempts to
                  commit identity theft;
                </li>
                <li>For developing new products and services;</li>
                <li>
                  For enhancing, improving, repairing, maintaining, or modifying
                  our current products and services, as well as undertaking
                  quality and safety assurance measures;
                </li>
                <li>
                  For identifying usage trends, for example, understanding which
                  parts of our Services are of most interest to users;
                </li>
                <li>
                  For determining the effectiveness of our promotional
                  campaigns; and
                </li>
                <li>For operating and expanding our business activities.</li>
              </ul>
            </li>
          </ul>

          <p>
            WWe need to collect Personal Information in order to provide the
            requested Services to you. If you do not provide the information
            requested, we may not be able to provide the Services. If you
            disclose any Personal Information relating to other people to us or
            to our service providers in connection with the Services, you
            represent that you have the authority to do so and to permit us to
            use the information in accordance with this Privacy Policy.
          </p>

          <h3 className="text-xl text-[#14576E] mt-5">
            Disclosure of Personal Information
          </h3>
          <p>We may disclose Personal Information:</p>
          <ul>
            <li>
              To our affiliates, for the purposes described in this Privacy
              Policy.
            </li>
            <li>
              To our third party service providers, to facilitate services they
              provide to us, such as website hosting, data analysis, fraud
              prevention, information technology and related infrastructure
              provision, customer service, email delivery, auditing, and other
              services.
            </li>
            <li>
              By using the Services, you may elect to disclose Personal
              Information.
              <ul>
                <li>
                  Through your social sharing activity. When you connect your
                  Worksense account to your social media or other third-party
                  account, you will share information with your friends
                  associated with your social media account, with other users,
                  and with your third-party account provider. By doing so, you
                  authorize us to facilitate this sharing of information, and
                  you understand that the use of shared information will be
                  governed by the third-party provider’s privacy policy.
                </li>
                <li>
                  On message boards, chat, blogs, and other services to which
                  you are able to post information and content. Please note that
                  any information you post or disclose through these services
                  will become public and may be available to other users and the
                  general public.
                </li>
              </ul>
            </li>
          </ul>

          <h3 className="text-xl text-[#14576E] mt-5">
            Other Uses and Disclosures
          </h3>
          <p>
            We may also use and disclose Personal Information as we believe to
            be necessary or appropriate: (a) to comply with applicable law, to
            respond to requests from public and government authorities, to
            cooperate with law enforcement, or for other legal reasons; (b) to
            enforce our terms and conditions; and (c) to protect our rights,
            privacy, safety or property, and/or that of you, or others. We may
            use, disclose or transfer your Personal Information to a third party
            in connection with any proposed or actual reorganization, merger,
            sale, joint venture, assignment, transfer or other disposition of
            all or any portion of our assets or stock (including in connection
            with any bankruptcy or similar proceedings).
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            INFORMATION COLLECTED AUTOMATICALLY
          </h2>
          <p>
            We automatically collect certain information from you when you use
            the Services, including to understand how the Services are used and
            to improve and enhance your experience. We and our service providers
            may collect information automatically in a variety of ways,
            including through:
          </p>

          <ul>
            <li>
              Your browser or device
              <ul>
                <li>
                  Certain information is collected by most browsers or
                  automatically through your device, such as your Media Access
                  Control (MAC) address, computer type (Windows or Mac), screen
                  resolution, operating system name and version, device
                  manufacturer and model, language, Internet browser type and
                  version, and the name and version of the Services you are
                  using. We use this information to ensure that the Services
                  function properly.{" "}
                </li>
              </ul>
            </li>
          </ul>

          <ul>
            <li>
              <b class="text-[#14576E]">Cookies</b>
              <ul>
                <li>
                  Cookies are pieces of information stored directly on the
                  computer that you are using. Cookies allow us to collect
                  information such as browser type, time spent on the Services,
                  pages visited, language preferences, and other traffic data.
                  We and our service providers use the information for security
                  purposes, to facilitate navigation, to display information
                  more effectively, and to personalize your experience. We also
                  gather statistical information about use of the Services in
                  order to continually improve their design and functionality,
                  understand how they are used, and assist us with resolving
                  questions regarding them. We do not currently respond to
                  browser do-not-track signals. If you do not want information
                  collected through the use of cookies, most browsers allow you
                  to automatically decline cookies or be given the choice of
                  declining or accepting a particular cookie (or cookies) from a
                  particular website. You may also wish to refer to
                  http://www.allaboutcookies.org/manage-cookies/index.html. If,
                  however, you do not accept cookies, you may experience some
                  inconvenience in your use of the Services.{" "}
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <b class="text-[#14576E]">
                Pixel tags and other similar technologies
              </b>
              <ul>
                <li>
                  <b class="text-[#14576E]">Pixel tags.</b> Pixel tags (also
                  known as web beacons and clear GIFs) may be used to, among
                  other things, track the actions of users of the Services
                  (including email recipients), measure the success of our
                  marketing campaigns, and compile statistics about usage of the
                  Services and response rates.{" "}
                </li>
                <li>
                  <b class="text-[#14576E]">Analytics.</b> We use Google
                  Analytics, which uses cookies and similar technologies to
                  collect and analyze information about use of the Services and
                  report on activities and trends. This service may also collect
                  information regarding the use of other websites, apps and
                  online resources. You can learn about Google’s practices by
                  going to www.google.com/policies/privacy/partners/, and
                  exercise the opt-out provided by Google by downloading the
                  Google Analytics opt-out browser add-on, available at
                  https://tools.google.com/dlpage/gaoptout.{" "}
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <b class="text-[#14576E]">IP Address</b>
              <ul>
                <li>
                  Your IP Address is a number that is automatically assigned to
                  your computer by your Internet Service Provider. An IP Address
                  may be identified and logged automatically in our server log
                  files whenever a user accesses the Services, along with the
                  time of the visit and the pages visited. We use IP Addresses
                  for purposes such as calculating usage levels, diagnosing
                  server problems, and administering the Services. We may also
                  derive your approximate location from your IP Address.
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              <b class="text-[#14576E]">• Physical Location</b>
              <ul>
                <li>
                  We may collect the physical location of your device by, for
                  example, using satellite, cell phone tower or WiFi signals. We
                  may use your device’s physical location to [provide you with
                  personalized location-based services and content.] [We may
                  also share your device’s physical location, combined with
                  information about what advertisements you viewed and other
                  information we collect, with our marketing partners to enable
                  them to provide you with more personalized content and to
                  study the effectiveness of advertising campaigns.] In some
                  instances, you may be permitted to allow or deny such uses
                  and/or sharing of your device’s location[, but if you do, we
                  and/or our marketing partners may not be able to provide you
                  with the applicable personalized services and content].
                </li>
              </ul>
            </li>
          </ul>
          <h2 className="text-2xl text-[#14576E] mt-6">
            Uses and Disclosures of Information Collected Automatically
          </h2>
          <p>
            We may use and disclose information collected automatically for any
            purpose, except where we are required to do otherwise under
            applicable law. If we are required to treat such information as
            Personal Information under applicable law, we may use and disclose
            it for the purposes for which we use and disclose Personal
            Information as detailed in this Privacy Policy. In some instances,
            we may combine information collected automatically with Personal
            Information. If we do, we will treat the combined information as
            Personal Information as long as it is combined.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">SECURITY</h2>
          <p>
            We seek to use reasonable organizational, technical and
            administrative measures designed to protect Personal Information
            within our organization. Unfortunately, no data transmission or
            storage system can be guaranteed to be 100% secure. If you have
            reason to believe that your interaction with us is no longer secure,
            please immediately notify us in accordance with the “Contact Us”
            section below.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">YOUR CHOICES</h2>
          <p>
            If you no longer want to receive marketing-related emails from us on
            a going-forward basis, you may opt-out by contacting us at{" "}
            <a href="mailto:arshan@worksenseai.com" className="text-[#14576E]">
              arshan@worksenseai.com
            </a>
            . Please note that if you opt out of receiving marketing-related
            emails from us, we may still send you important administrative
            messages, from which you cannot opt out.
          </p>
          <p>
            If you no longer want to receive SMS/text messages from us on a
            going-forward basis, you may opt out by contacting us at{" "}
            <a href="mailto:arshan@worksenseai.com" className="text-[#14576E]">
              arshan@worksenseai.com
            </a>
            .
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">THIRD-PARTY SERVICES</h2>
          <p>
            This Privacy Policy does not address, and we are not responsible
            for, the privacy, information, or other practices of any third
            parties, including any third party operating any website or service
            to which the Services link. The inclusion of a link on the Services
            does not imply endorsement of the linked site or service by us or by
            our affiliates.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            SENSITIVE INFORMATION
          </h2>
          <p>
            Unless we request it, we ask that you not send us, and you not
            disclose, any sensitive Personal Information (e.g., Social Security
            numbers, information related to racial or ethnic origin, political
            opinions, religion or other beliefs, health, biometrics or genetic
            characteristics, or criminal background) on or through the Services
            or otherwise to us..
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            USE OF SERVICES BY MINORS
          </h2>
          <p>
            The Services are not directed to individuals under the age of
            thirteen (13), and we do not knowingly collect Personal Information
            from individuals under 13.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            JURISDICTION AND CROSS-BORDER TRANSFERS
          </h2>
          <p>
            We are located in the United States. We may store and process your
            Personal Information in any country where we have facilities or in
            which we engage service providers. By using the Services, you
            understand that your information will be transferred to countries
            outside of your country of residence, including the United States,
            which may have data protection rules that are different from those
            of your country.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">
            UPDATES TO THIS PRIVACY POLICY
          </h2>
          <p>
            The “LAST UPDATED” legend at the top of this Privacy Policy
            indicates when this Privacy Policy was last revised. Any changes
            will become effective when we post the revised Privacy Policy on the
            Services.
          </p>

          <h2 className="text-2xl text-[#14576E] mt-6">CONTACT US</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact
            us at{" "}
            <a href="mailto:arshan@worksenseai.com" className="text-[#14576E]">
              arshan@worksenseai.com
            </a>
            . Because email communications are not always secure, please do not
            include credit card or other sensitive information in your emails to
            us.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
