import React from "react";
import { motion } from "framer-motion";
import { useSpring, animated } from "react-spring";
import TrustedBySection from "./TrustedBySection";
import productImage1 from "../../images/productImage1.png";
import { AnimatedBackground } from "../../utils/Animations";

const fadeIn = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const HeroSection = () => {
  const springProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  return (
    <div className="relative bg-gradient-to-r from-cyan-500 to-blue-500 text-white min-h-screen flex items-center pt-10 sm:pt-12 lg:pt-0 xl:pt-0">
      <AnimatedBackground />
      <div className="z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 w-full">
        <div className="flex flex-col md:flex-row items-center justify-between gap-8">
          <div className="md:w-1/2 space-y-6 text-center md:text-left">
            <motion.h1
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              className="text-4xl md:text-6xl font-extrabold leading-tight"
            >
              Boost Employee Sentiment and Retention with Worksense
            </motion.h1>
            <motion.p
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              className="text-lg md:text-2xl mb-6 font-light"
            >
              Eliminate the need for surveys by automatically capturing sentiment, driving stronger retention of employees.
            </motion.p>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={fadeIn}
              className="flex justify-center md:justify-start"
            >
              <a
                href="/signup"
                className="inline-block px-12 py-6 font-semibold text-xl text-white bg-gradient-to-r from-blue-700 to-blue-900 hover:bg-gradient-to-l focus:outline-none focus:ring-4 focus:ring-blue-500 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out transform hover:-translate-y-1"
                aria-label="Get Started with Worksense"
              >
                Get Started
              </a>
            </motion.div>
          </div>
          <div className="md:w-1/2 flex justify-center relative">
            <div className="relative">
              <animated.img
                src={productImage1}
                alt="Eliminate Surveys"
                style={springProps}
                className="w-full h-auto max-w-2xl transform transition duration-300 hover:scale-105 shadow-xl rounded-xl"
              />
            </div>
          </div>
        </div>
        <div className="mt-12">
          <TrustedBySection />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
