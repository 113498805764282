const TotalMessagesCard = ({ title, value }) => {
  return (
    <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col items-center justify-center">
      <h3 className="text-lg font-semibold text-gray-600">{title}</h3>
      <p className="text-3xl font-bold text-gray-900 mt-2">{value}</p>
    </div>
  );
};

export default TotalMessagesCard;
