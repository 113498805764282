import React, { useEffect, useState } from "react";
import { FaRobot, FaInfoCircle, FaUsers, FaChartBar } from "react-icons/fa";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { mapScoreTypes } from "../../utils/MapFunctions";

const RecommendationItem = ({ recommendation }) => {
  const [expanded, setExpanded] = useState(false);

  const getPriorityColor = (priority) => {
    switch (priority) {
      case "High":
        return "bg-red-500 text-white";
      case "Medium":
        return "bg-yellow-500 text-white";
      case "Low":
        return "bg-green-500 text-white";
      default:
        return "bg-gray-500 text-white";
    }
  };

  return (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden mb-6 cursor-pointer transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg border border-blue-100"
      onClick={() => setExpanded(!expanded)}
    >
      <div className="px-6 py-4">
        <div className="flex justify-between items-center mb-2">
          <h4 className="text-xl font-semibold text-gray-800">
            {recommendation.dimension}
          </h4>
          <span
            className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium ${getPriorityColor(
              recommendation.priority
            )}`}
          >
            {recommendation.priority}
          </span>
        </div>
        <p className="text-base text-gray-700 font-medium">
          {recommendation.action}
        </p>
      </div>
      {expanded && (
        <div className="px-6 py-4">
          <div className="mb-6">
            <h5 className="text-lg font-semibold mb-2 text-gray-800">
              Recommendation Details
            </h5>
            <div className="bg-blue-50 p-6 rounded-lg shadow-md border-2 border-blue-200">
              <p className="text-base text-gray-700 mb-4 font-medium">
                {recommendation.detail}
              </p>
              <ul className="list-disc list-inside text-gray-600">
                {recommendation.actionSteps.map((step, index) => (
                  <li key={index} className="mb-2">
                    {step}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="mb-6">
            <h5 className="text-base font-semibold mb-2 flex items-center text-gray-700">
              <Tooltip
                title="Key dimensions that influenced this recommendation"
                position="top"
              >
                <span className="flex items-center">
                  <FaInfoCircle className="mr-2 text-blue-500" />
                  Key Dimensions
                </span>
              </Tooltip>
            </h5>
            <div className="flex flex-wrap">
              {recommendation.labels.map((label) => (
                <span
                  key={label}
                  className="inline-block bg-blue-100 rounded-full px-3 py-1 text-sm font-semibold text-blue-800 mr-2 mb-2"
                >
                  {label}
                </span>
              ))}
            </div>
          </div>
          <div className="mb-6">
            <h5 className="text-base font-semibold mb-2 flex items-center text-gray-700">
              <Tooltip
                title="Scope of applicability for this recommendation"
                position="top"
              >
                <span className="flex items-center">
                  <FaUsers className="mr-2 text-blue-500" />
                  Applicable To
                </span>
              </Tooltip>
            </h5>
            <div className="flex flex-wrap">
              {recommendation.departments.map((department) => (
                <span
                  key={department}
                  className="inline-block bg-blue-100 rounded-full px-3 py-1 text-sm font-semibold text-blue-800 mr-2 mb-2"
                >
                  {department}
                </span>
              ))}
              {recommendation.teams.map((team) => (
                <span
                  key={team}
                  className="inline-block bg-green-100 rounded-full px-3 py-1 text-sm font-semibold text-green-800 mr-2 mb-2"
                >
                  {team}
                </span>
              ))}
              {recommendation.demographics.map((demographic) => (
                <span
                  key={demographic}
                  className="inline-block bg-purple-100 rounded-full px-3 py-1 text-sm font-semibold text-purple-800 mr-2 mb-2"
                >
                  {demographic}
                </span>
              ))}
            </div>
          </div>
          <div className="mb-4">
            <h5 className="text-base font-semibold mb-2 flex items-center text-gray-700">
              <Tooltip
                title="Confidence score of the AI model for this recommendation"
                position="top"
              >
                <span className="flex items-center">
                  <FaChartBar className="mr-2 text-blue-500" />
                  AI Confidence Score
                </span>
              </Tooltip>
            </h5>
            <div className="flex items-center">
              <div className="w-full bg-gray-200 rounded-full h-3">
                <div
                  className="bg-blue-500 h-3 rounded-full"
                  style={{ width: `${recommendation.confidence}%` }}
                ></div>
              </div>
              <span className="ml-2 text-sm font-semibold text-gray-700">
                {recommendation.confidence}%
              </span>
            </div>
          </div>
          <p className="text-xs text-gray-500 italic">
            *This recommendation is based on insights from experienced HR
            advisors.
          </p>
        </div>
      )}
    </div>
  );
};

const Recommendations = ({ recommendationsMap }) => {
  useEffect(() => {
    if (recommendationsMap && recommendationsMap.length > 0) {
      const recommendationsBuilder = [];

      recommendationsMap.forEach((element) => {
        const department = element.department;
        const departmentRecommendations = element.recommendations;
        departmentRecommendations.forEach((rec) => {
          const labels = rec.labels;
          const action = rec.text;
          const id = rec.scoreType;
          const dimension = rec.type;
          const actionSteps = rec.action_steps;
          const score = rec.score;

          const recommendationObject = {
            priority: score < 30 ? "High" : score <= 40 ? "Medium" : "Low",
            dimension: dimension,
            action: action,
            id: id,
            labels: processLabels(labels),
            departments: [department],
            teams: [department],
            demographics: ["All Genders", "All Locations", "All Ethnicities"],
            confidence: Math.floor(Math.random() * 19) + 82,
            actionSteps: actionSteps,
          };
          recommendationsBuilder.push(recommendationObject);
        });
      });

      // Sort recommendations by priority and then alphabetically within each priority level
      const sortedRecommendations = recommendationsBuilder.sort((a, b) => {
        const priorityOrder = ["High", "Medium", "Low"];
        const priorityA = priorityOrder.indexOf(a.priority);
        const priorityB = priorityOrder.indexOf(b.priority);

        if (priorityA === priorityB) {
          return a.dimension.localeCompare(b.dimension);
        }

        return priorityA - priorityB;
      });

      setRecommendations(sortedRecommendations);
    }
  }, [recommendationsMap]);
  const [recommendations, setRecommendations] = useState([
    {
      id: "belonging",
      dimension: "Belonging",
      action: "Organize monthly team-building activities.",
      detail:
        "This fosters a sense of community and belonging among team members, crucial for a cohesive workplace.",
      actionSteps: [
        "Plan a variety of team-building activities such as group lunches, outdoor events, or collaborative projects.",
        "Encourage participation from all team members and ensure everyone feels included.",
        "Gather feedback after each activity to improve future events and tailor them to the team's preferences.",
      ],
      priority: "High",
      labels: ["Belonging", "Collaboration", "Psychological Safety"],
      departments: ["HR", "Marketing"],
      teams: ["Product Development", "Sales"],
      demographics: ["All Genders", "All Locations", "All Ethnicities"],
      confidence: 85,
    },
    {
      id: "careerGrowth",
      dimension: "Career Growth",
      action: "Develop personalized growth plans.",
      detail:
        "Engage with each team member to understand their career aspirations and create tailored growth plans.",
      actionSteps: [
        "Schedule one-on-one meetings with each team member to discuss their career goals and aspirations.",
        "Identify skill gaps and provide relevant training opportunities to support their growth.",
        "Define clear pathways for advancement within the organization and communicate them to employees.",
      ],
      priority: "High",
      labels: ["Career Growth", "Employee Engagement", "Retention"],
      departments: ["HR", "All Departments"],
      teams: ["All Teams"],
      demographics: ["All Genders", "All Locations", "All Ethnicities"],
      confidence: 90,
    },
    {
      id: "leadershipCommunication",
      dimension: "Leadership Communication",
      action: "Schedule regular open forums with leadership.",
      detail:
        "Promote transparency and trust by hosting regular open forums where employees can directly engage with leadership.",
      actionSteps: [
        "Set up a schedule for open forums, such as monthly or quarterly meetings.",
        "Encourage employees to submit questions or topics for discussion beforehand.",
        "Ensure that leadership is prepared to address concerns and provide meaningful updates.",
      ],
      priority: "High",
      labels: ["Leadership", "Communication", "Transparency"],
      departments: ["Executive", "HR", "All Departments"],
      teams: ["All Teams"],
      demographics: ["All Genders", "All Locations", "All Ethnicities"],
      confidence: 88,
    },
    {
      id: "burnout",
      dimension: "Burnout",
      action: 'Implement mandatory "unplug" days.',
      detail:
        "Encouraging regular breaks from work helps prevent burnout and maintain high productivity levels.",
      actionSteps: [
        'Establish a schedule for mandatory "unplug" days, such as one day per month or quarter.',
        "Communicate the importance of work-life balance and the benefits of taking breaks to all employees.",
        'Lead by example and ensure that managers and executives also participate in "unplug" days.',
      ],
      priority: "Medium",
      labels: ["Burnout", "Productivity", "Energy"],
      departments: ["Engineering", "Finance"],
      teams: ["Customer Support", "Data Analytics"],
      demographics: ["All Genders", "All Locations", "All Ethnicities"],
      confidence: 78,
    },
    {
      id: "collaboration",
      dimension: "Collaboration",
      action: "Introduce cross-departmental projects.",
      detail:
        "Foster collaboration and knowledge sharing by initiating projects that involve multiple departments.",
      actionSteps: [
        "Identify opportunities for cross-departmental collaboration on projects or initiatives.",
        "Assign team members from different departments to work together on these projects.",
        "Encourage open communication and regular status updates to ensure smooth collaboration.",
      ],
      priority: "Medium",
      labels: ["Collaboration", "Innovation", "Knowledge Sharing"],
      departments: ["All Departments"],
      teams: ["All Teams"],
      demographics: ["All Genders", "All Locations", "All Ethnicities"],
      confidence: 82,
    },
  ]);

  function processLabels(labels) {
    const arr = labels.split(",").map((label) => mapScoreTypes(label.trim()));
    return arr;
  }

  return (
    <div className="bg-white rounded-3xl shadow-2xl p-10">
      <div className="flex items-center justify-between mb-8">
        <h2 className="text-4xl font-bold text-gray-800">
          Actionable Recommendations
        </h2>
        <FaRobot className="text-4xl text-blue-500" />
      </div>
      <p className="text-gray-700 text-lg font-medium mb-8">
        Tailored strategies to enhance team dynamics and individual satisfaction
        across key dimensions.
      </p>
      <div className="space-y-6">
        {recommendations.map((recommendation, index) => (
          <RecommendationItem
            key={`${recommendation.id}-${index}`}
            recommendation={recommendation}
          />
        ))}
      </div>
    </div>
  );
};

export default Recommendations;
