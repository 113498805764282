import React from 'react'

const EditButton = ({
  isEditing,
  handleUpdateClick,
  handleExitClick,
  message,
}) => {
  return (
    <div className="flex flex-col items-center">
      {isEditing && (
        <div className="flex justify-center mb-4">
          <button className="btn btn-grey mr-2" onClick={handleUpdateClick}>
            Update
          </button>
          <button className="btn btn-grey" onClick={handleExitClick}>
            Cancel
          </button>
        </div>
      )}
      {message && (
        <p
          className={`text-sm ${
            message === "Successfully updated!"
              ? "text-gray-500"
              : "text-black-500"
          }`}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default EditButton;
