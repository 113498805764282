import React from "react";
import { CgProfile } from "react-icons/cg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { GiTeamIdea } from "react-icons/gi";
import { FaHandsHelping, FaRegSmileBeam } from "react-icons/fa";
import { MdSafetyDivider } from "react-icons/md";
import { BsGraphUp } from "react-icons/bs";
import { AiOutlineRise, AiOutlineClose } from "react-icons/ai";

const getProgressDetails = (value) => {
  if (value >= 81) {
    return {
      color: "rgb(119, 158, 134)",
      score: "Very Positive",
      attritionRisk: "Very Low",
      attritionRiskBgColor: "rgba(119, 158, 134, 0.2)",
    };
  } else if (value >= 61) {
    return {
      color: "rgb(140, 198, 141)",
      score: "Positive",
      attritionRisk: "Low",
      attritionRiskBgColor: "rgba(140, 198, 141, 0.2)",
    };
  } else if (value >= 41) {
    return {
      color: "rgb(180, 227, 180)",
      score: "Neutral",
      attritionRisk: "Moderate",
      attritionRiskBgColor: "rgba(180, 227, 180, 0.2)",
    };
  } else if (value >= 21) {
    return {
      color: "rgb(232, 132, 132)",
      score: "Negative",
      attritionRisk: "High",
      attritionRiskBgColor: "rgba(232, 132, 132, 0.2)",
    };
  }
  return {
    color: "rgb(205, 105, 105)",
    score: "Very Negative",
    attritionRisk: "Very High",
    attritionRiskBgColor: "rgba(205, 105, 105, 0.2)",
  };
};

const getIconForDimensionName = (dimensionName, score) => {
  const { color, attritionRisk } = getProgressDetails(score);

  const iconProps = {
    color: color,
    size: 24,
    title: `${dimensionName} Risk: ${attritionRisk}`,
  };

  switch (dimensionName) {
    case "Wellbeing":
      return <FaRegSmileBeam {...iconProps} />;
    case "Performance":
      return <FaHandsHelping {...iconProps} />;
    case "Engagement":
      return <BsGraphUp {...iconProps} />;
    case "Growth":
      return <AiOutlineRise {...iconProps} />;
    case "Leadership_and_Vision":
      return <MdSafetyDivider {...iconProps} />;
    default:
      return <GiTeamIdea {...iconProps} />;
  }
};

const formatTitle = (title) => {
  return title.replace(/_/g, " ");
};

const PerformerCard = ({
  performerType,
  id,
  name,
  team,
  department,
  overallSentiment,
  dimensionScores,
  onRemove,
  hasEnoughData,
}) => {
  const { color: sentimentColor, score: sentimentScore } =
    getProgressDetails(overallSentiment);

  const getBarColor = (score) => {
    return getProgressDetails(score).color;
  };

  

  return (
    <div className="max-w-full rounded overflow-hidden shadow-lg bg-white m-4 flex flex-row items-stretch relative">
      {performerType !== "highPerformers" && (
        <div className="absolute right-0 top-0 p-2">
          <AiOutlineClose
            className="text-red-500 cursor-pointer hover:text-gray-800"
            size="16px"
            onClick={() => onRemove(id)}
          />
        </div>
      )}
      <div
        className={`flex flex-col items-center p-4 bg-white rounded-lg ${
          !hasEnoughData ? "mr-2" : "mr-4 flex-1"
        }`}
      >
        <CgProfile size="40px" />
        <div className="text-black font-semibold text-lg mt-2">{name}</div>
        <p className="text-gray-700 text-base text-center">{team}</p>
        <p className="text-gray-700 text-base mb-4">{department}</p>
      </div>

      {!hasEnoughData ? (
        <div className="flex-1 flex justify-center items-center p-4 mr-2 text-center">
          <p className="text-gray-500 font-semibold text-2xl">
            Not Enough Data Collected For This User With the Given Filters
          </p>
        </div>
      ) : (
        <>
          <div
            className="flex-1 p-4 bg-white shadow-md rounded-lg mr-4 mb-4"
            style={{
              backgroundColor:
                getProgressDetails(overallSentiment).attritionRiskBgColor,
            }}
          >
            <div className="text-center mb-4">
              <p className="font-semibold text-base">Attrition Risk:</p>
              <p
                className="font-bold text-base"
                style={{ color: sentimentColor }}
              >
                {getProgressDetails(overallSentiment).attritionRisk}
              </p>

              <div className="mt-2 flex justify-center flex-wrap">
                {Object.entries(dimensionScores).map(([dimension, score]) => (
                  <div
                    key={dimension}
                    className="p-1"
                    style={{ minWidth: "30%", maxWidth: "30%" }}
                    title={`${dimension}: ${score} - Risk: ${
                      getProgressDetails(score).attritionRisk
                    }`}
                  >
                    {getIconForDimensionName(dimension, score)}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="flex-1 p-4 bg-white rounded-lg mr-4 flex flex-col items-center justify-center">
            <div className="text-center">
              <p className="text-gray-700 font-semibold text-base mb-2">
                Overall Sentiment
              </p>
              <div className="mb-2 ml-5" style={{ width: 80, height: 80 }}>
                <CircularProgressbar
                  value={overallSentiment}
                  text={`${overallSentiment}%`}
                  styles={buildStyles({
                    pathColor: sentimentColor,
                    textColor: "black",
                  })}
                />
              </div>
              <p className="text-gray-700 font-bold">{sentimentScore}</p>
            </div>
          </div>

          <div
            className="flex-1 p-4 bg-white rounded-lg mr-4 flex items-center justify-center"
            style={{ flexBasis: "30%", paddingRight: '24px' }}
          >
            <div className="w-full">
              {Object.entries(dimensionScores).map(([title, score]) => (
                <div
                  className="flex items-center justify-between mb-2 w-full"
                  key={title}
                >
                  <div className="text-xs font-medium w-1/3">
                    {formatTitle(title)}
                  </div>
                  <span className="text-xs mr-2">{score}</span>
                  <div className="w-full bg-gray-200 rounded-full h-1.5">
                    <div
                      className="h-1.5 rounded-full"
                      style={{
                        width: `${score}%`,
                        backgroundColor: getBarColor(score),
                      }}
                    ></div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PerformerCard;