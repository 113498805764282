import { useState } from "react";
import Reports from "./Reports";
import TimeSeries from "./TimeSeries";
import TeamCharts from "./TeamCharts";
import HeatMapView from "./HeatMapView";
import PerformerPayWall from "./PerformerPayWall";
import PerformerView from "./PerformerView";

const Tabs = ({
  totalMessages,
  scoreTrendDayOverDayData,
  teams,
  handleLineDataTeamChange,
  scoreByTopData,
  scoreByBottomData,
  scoreMetaData,
  heatmapData,
  filters,
  isDemoUser,
  userTier,
}) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const getContentForUserTier = (isDemoUser, userTier, performerType) => {
    if (isDemoUser) {
      return <PerformerView performerType={performerType} filters={filters} />;
    }
    switch (userTier) {
      case "Base":
        return <PerformerPayWall userTier={userTier} />;
      case "Pro":
      case "Elite":
        return <PerformerView performerType={performerType} filters={filters} />;
      default:
        return <PerformerPayWall userTier={userTier} />;
    }
  };

  const tabs = [
    {
      title: "Reports",
      content: (
        <Reports
          data={{ totalMessages: totalMessages }}
          scoreMetadata={scoreMetaData}
          filters={filters}
          isDemoUser={isDemoUser}
        />
      ),
    },
    {
      title: "Heatmap",
      content: <HeatMapView data={heatmapData} />,
    },
    {
      title: "Time Series",
      content: (
        <TimeSeries
          data={{ scoreTrendDayOverDayData: scoreTrendDayOverDayData }}
          teams={teams}
          handleTeamChange={handleLineDataTeamChange}
          filters={filters}
        />
      ),
    },
    {
      title: "Team Data",
      content: (
        <TeamCharts
          data={{
            scoreByTopData: scoreByTopData,
            scoreByBottomData: scoreByBottomData,
          }}
          filters={filters}
        />
      ),
    },
  ];

  if (userTier === "Pro" || isDemoUser) {
    tabs.push(
      {
        title: "High Performers",
        content: getContentForUserTier(isDemoUser, userTier, "highPerformers"),
      },
      {
        title: "Real-Time Performers",
        content: getContentForUserTier(isDemoUser, userTier, "realTimePerformers"),
      }
    );
  } else if (userTier === "Elite") {
    tabs.push(
      {
        title: "High Performers",
        content: getContentForUserTier(isDemoUser, userTier, "highPerformers"),
      },
      {
        title: "Real-Time Performers",
        content: getContentForUserTier(isDemoUser, userTier, "realTimePerformers"),
      }
    );
  } else {
    tabs.push({
      title: "High Performers",
      content: getContentForUserTier(isDemoUser, userTier, "highPerformers"),
    });
  }

  return (
    <>
      <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700">
        <ul className="flex flex-wrap -mb-px">
          {tabs.map((tab, index) => (
            <li key={index} className="mr-2">
              <button
                className={
                  index === activeTabIndex
                    ? "inline-block p-4 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500"
                    : "inline-block p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                }
                onClick={() => setActiveTabIndex(index)}
              >
                {tab.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="tab-content">{tabs[activeTabIndex].content}</div>
    </>
  );
};

export default Tabs;