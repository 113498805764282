import React, { useState } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { saveHiBobData } from "../../utils/IntegrationHelper"; // We'll create this function next

const HiBobInit = () => {
  const [serviceId, setServiceId] = useState("");
  const [token, setToken] = useState("");

  const handleServiceIdChange = (event) => {
    setServiceId(event.target.value);
  };

  const handleTokenChange = (event) => {
    setToken(event.target.value);
  };

  const handleSaveClick = async () => {
    if (serviceId.trim() === "" || token.trim() === "") {
      alert("Service ID and Token are required.");
      return;
    }

    await saveHiBobData(serviceId, token);
    window.location.href = "/integrations";
  };

  return (
    <div className="space-y-4">
      <div className="flex flex-col">
        <label
          htmlFor="serviceId"
          className="text-sm font-medium text-gray-700 flex items-center"
        >
          Service ID
          <FaQuestionCircle
            className="ml-2 text-lg text-gray-500"
            title="Enter your hiBob Service ID"
          />
        </label>
        <input
          type="text"
          value={serviceId}
          onChange={handleServiceIdChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter Service ID"
        />
      </div>
      <div className="flex flex-col">
        <label
          htmlFor="token"
          className="text-sm font-medium text-gray-700 flex items-center"
        >
          Token
          <FaQuestionCircle
            className="ml-2 text-lg text-gray-500"
            title="Enter your hiBob API Token"
          />
        </label>
        <input
          type="text"
          value={token}
          onChange={handleTokenChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder="Enter Token"
        />
      </div>

      <button
        onClick={handleSaveClick}
        className="mt-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Save
      </button>
    </div>
  );
};

export default HiBobInit;